import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseApplicationDataService } from '../../service/base-application-data.service';
import { first } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm, AbstractControl } from '@angular/forms';
import { ProfileService } from '../../service/profile.service'
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { LanguageService } from '../../service/language.service';
declare var $: any;

@Component({
  selector: 'app-trd-consentform',
  templateUrl: './trd-consentform.component.html',
  styleUrls: ['./trd-consentform.component.scss']
})
export class TrdConsentformComponent implements OnInit {
  userall: any = {};
  userselect: any = {};
  unitholderno: any = "init";
  formchangepass: FormGroup;
  isNotValid = false;
  res: any = {};
  formotp: FormGroup;
  message: any;
  loading = false;
  email = false;
  sms = false;
  dataconsent:any = {};
  data: any = {};
  userInfo:any = {}; 
  lang: Observable<string>;
  idCardNo:"";
  unitHolderName:"";
  consentDate:"";
  consentFlag="";
  idCardNomark="";
  consentYear=[];
  year: any =[];
  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private basedataservice: BaseApplicationDataService,
    private profileservice: ProfileService,
    private toastr: ToastrService,
    private langservice: LanguageService,
  ) 
  {
    translate.addLangs(["th", "en"]);
  }

  ngOnInit() {
    
    this.createFormValidate();
    //this.getSelectListUnitholder();
    var date = new Date();
    var yy=date.getFullYear()+543;
    //var yy=2570;
    this.year=[];
    for (let index = yy-1; index >= 2565; index--) {
      this.year.push({
         value:index,
         checked:false
      });
    }
    //this.year[3].checked=true;
    this.year=this.year.splice(0,10);
    this.year.reverse();
    this.getTBDataDic();

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    //console.info(this.userInfo);
    this.langservice.listen().subscribe((m: any) => {
      console.log(m);
      this.lang = m;
    });
    $('#mutual-tab-menu').find('li').removeClass('current');
    $('#mutual-tab-menu').find('li#menu5').addClass('current');
  }

  onChange() {

    for (let i = 0; i < this.userall.unitholderlist.length; i++) {
      if (this.userall.unitholderlist[i].UnitholderId == this.unitholderno.UnitholderId) {
        this.userselect = this.userall.unitholderlist[i];
      }
    }
  }
  getSelectListUnitholder() {
    this.basedataservice.getSelectListUnitholder()
      .pipe(first())
      .subscribe(
        data => {
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
        }, 100);
          this.userall = data;
          this.unitholderno = this.userall.unitholderlist[0];
          this.userselect = this.userall.unitholderlist[0];
        },
        error => {
          console.log(error)

        });
  }
  resetotp(){
    this.formotp.reset();
  }
  numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
      }
      return true;
  }
  get  getChecked(){
   
    return this.year
    .filter(item=>item.checked)
    .map(opt => opt.value)
  }
  onCheckboxChange(event,index) {
    //console.info(event.target.value);
    this.consentFlag="Y";
    this.year[index].checked=event.target.checked;
  }
  onResetCheckbox() {
     //alert(this.consentFlag);
     
     this.year.forEach((e) => {
        e.checked=false;
     });
  }
  onSubmit() {
    //console.log(this.formotp.controls.otp.value)
    //console.log(this.formchangepass)
    //if (this.formotp.valid) {
      this.isNotValid = false;
     
      /*
      let Params = new HttpParams();
      Params = Params.append('otp', this.formotp.controls.otp.value);
      Params = Params.append('refcode', this.res.refcode);
      */
  
      this.loading = true;
      this.dataconsent = {
        idCardNo: this.userInfo.IDCardNo,
        consentFlag:  this.consentFlag,
        consentYear: this.getChecked,
        source:"Web Online"
      }
      //console.error(this.dataconsent );
      
      this.basedataservice.saveconsentrmfform2(this.dataconsent, null)
        .pipe(first())
        .subscribe((data: any) => {   
            this.loading = false;       
          this.consentDate = data.consentDate.toString('dd/MM/yyyy');
          //this.resetotp()
            //$('#otpchangeconsentrmfform').modal('toggle');
            this.getTBDataDic();
            this.translate.get('CONTENT.save-consent-rmf-form-success').subscribe((res: string) => {
                this.message = res;
            });
            $('#message').modal({
              backdrop: 'static',
              keyboard: false,
              show: true
            });
          },
          error => {
            console.log(error)
            this.loading = false;
            this.message = error.error.messages;
          });

    //} else {
      //this.isNotValid = true;
      //this.validateAllFormFields(this.formotp);
    //}

  }
  /*
  onSubmit() {
      //this.isNotValid = false;
      this.loading = true;         
      this.dataconsent = {
        idCardNo: this.idCardNo,
        consentFlag: this.consentFlag
      }
      this.profileservice.submittrdconsentform(this.dataconsent)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data)            
            this.res = data;
            this.loading = false;
            $('#otpchangeconsentform').modal({
              backdrop: 'static',
              keyboard: false,
              show: true
            });
          },
          error => {
            console.log(error)
            this.loading = false;
            this.message = error.error.messages;
            $('#message').modal({
              backdrop: 'static',
              keyboard: false,
              show: true
            });
          });
  }

  saveconsentform() {
    console.log(this.formotp.controls.otp.value)
    console.log(this.formchangepass)
    if (this.formotp.valid) {
      this.isNotValid = false;
      this.loading = true;
      let Params = new HttpParams();
      Params = Params.append('otp', this.formotp.controls.otp.value);
      Params = Params.append('refcode', this.res.refcode);

      this.profileservice.savetrdconsentform(this.dataconsent, Params)
        .pipe(first())
        .subscribe((data: any) => {          
          this.consentDate = data.consentDate.toString('dd/MM/yyyy');
            this.loading = false;
            $('#otpchangeconsentform').modal('toggle');
            this.translate.get('CONTENT.save-consentform-success').subscribe((res: string) => {
              this.message = res;
          });
           // this.message = "ท่านได้ทำการเปลี่ยนความยินยอมเปิดเผยข้อมูลส่วนบุคคลเรียบร้อยแล้ว";
            $('#message').modal({
              backdrop: 'static',
              keyboard: false,
              show: true
            });
          },
          error => {
            console.log(error)
            this.loading = false;
            this.message = error.error.messages;
          });

    } else {
      this.isNotValid = true;
      this.validateAllFormFields(this.formotp);
    }

  }

  */
  getTBDataDic() { 
      this.profileservice.gettrdtbdatadic(null)
      .pipe(first())
      .subscribe(
        (data: any) => {  
          this.consentFlag = data.ConsentFlag;
          this.idCardNo = data.idCardNo;
          this.idCardNomark=data.idCardNomark;
          this.unitHolderName = data.unitHolderName;
          this.consentDate = data.consentDate;
          this.loading = false;     
          data.consentYear.forEach((e) => {
            var index = this.year.findIndex((o)=>e==o.value);
            //console.info(index);
            if(index>-1) {
              this.year[index].checked=true;
              this.year[index].edit=true;
            }
        });    
        },
        error => {
          console.log(error)
          this.loading = false;
          this.message = error.error.messages;
        });
    }
    
  createFormValidate() {
    this.formchangepass = this.fb.group({
      currentpassword: [null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[A-Z])(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/)
        ]
      ],
      newpassword: [null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[A-Z])(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/)
        ]
      ]
    })

    this.formotp = this.fb.group({
      otp: [null,
        [
          Validators.required,
          Validators.pattern(/^[0-9]*$/)
        ]
      ]
    })
  }
  reset(){
    this.message = "";
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    })
  }

  isFieldNotValid(field: string) {
    return !this.formchangepass.get(field).valid && this.formchangepass.get(field).touched

  }

  ValidatorDisplayCss(field: string) {
    return {
      'has-danger': this.isFieldNotValid(field)
    };
  }

  isFieldNotValid4(field: string) {
    return !this.formotp.get(field).valid && this.formotp.get(field).touched

  }

  ValidatorDisplayCss4(field: string) {
    return {
      'has-danger': this.isFieldNotValid4(field)
    };
  }
  /*
  requestotp() {
    this.loading = true;
    this.basedataservice.requestotp()
      .subscribe((data) => {
        console.log(data);
        this.res = data;
        this.loading = false;
      },
        (error) => {
          console.log(error);
          this.loading = false;
          this.message = error.error.messages;
            setTimeout(() => {
              $('#message').modal({
                backdrop: 'static',
                keyboard: false,
                show: true
              });
            }, 100);
        });
  }
  */
  print() {
    window.focus();
    window.print();
  }
}
