import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../service/authentication.service'
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { HttpParams } from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'app-signin-otp',
  templateUrl: './signin-otp.component.html',
  styleUrls: ['./signin-otp.component.scss']
})
export class SigninOTPComponent implements OnInit {
  formotp: FormGroup;
  isNotValid = false;
  langth: boolean;
  langen: boolean;
  foo1: string;
  returnUrl: any;
  User: any = {}
  username = "";
  message: any = "";  
  refcode: any = "";
  refotp: any = "";
  loading = false;  
  exit: boolean = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private http: HttpClient) {
    translate.addLangs(["th", "en"]);
  }

  ngOnInit() {
    this.createFormValidate();
    this.foo1 = this.translate.currentLang;
    if (this.foo1 == 'th') {

      this.langth = true;
    }
    else if (this.foo1 == 'en') {
      this.langen = true;
    }
    this.route.params.subscribe(data => {
      this.refcode = data.refcode
      this.refotp = data.refotp
      this.getsigninotp();
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/logingin';
  }
  switchlang(lang) {
    if (lang == 'th') {
      this.translate.use('th');
      this.langen = false;
      this.langth = true;
      localStorage.setItem('lang', lang);

    }
    else if (lang == 'en') {
      this.translate.use('en');
      this.langth = false;
      this.langen = true;
      localStorage.setItem('lang', lang);
    }


  }
  createFormValidate() {    
    this.formotp = this.fb.group({
      otp: [null,
        [
          Validators.required,
          Validators.pattern(/^[0-9]*$/)
        ]
      ]
    })
  }
  resetotp(){
    this.formotp.reset();
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    })
  }
  getsigninotp(){
    let Params = new HttpParams();
    Params = Params.append('refcode', this.refcode);
    this.authenticationService.getsigninotp(Params)
    .subscribe(
      (data: any) => {       
        this.refotp = data.refotp;
      },
      error => {
        console.log(error)
        this.message = error.error.messages;
        setTimeout(() => {
          $('#message2').modal({
            backdrop: 'static',
            keyboard: false,
            show: true
          });
        }, 100);

      });
  }
  
  requestsigninotp() {
    this.loading = true;  
    let Params = new HttpParams();
    Params = Params.append('refcode', this.refcode);    
    this.authenticationService.requestsigninotp(Params)
      .subscribe((data: any) => {
        console.log(data);
        this.refcode = data.refcode;
        this.refotp = data.refotp;
        this.loading = false;
        this.message=""
      },
        (error) => {
          console.log(error);
          this.loading = false;
          this.message = error.error.messages;
            setTimeout(() => {
              $('#message1').modal({
                backdrop: 'static',
                keyboard: false,
                show: true
              });
            }, 100);
        });
  }
  __onSubmit(){
    var settings = {
      "url": environment.serverUrl + '/authorize',
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept-Language": this.translate.currentLang,
        "refcode": this.refcode,
        "otp": this.formotp.controls.otp.value
      },
      "data": {
        "grant_type": "password",
        "client_id": "WebApp",
        "client_secret": "LHFundApp"
      }
    };
    
    $.ajax(settings).done(function (data) {
      localStorage.setItem('currentUser', JSON.stringify(data));
      localStorage.setItem('userInfo', data['memberInfo']);
      this.router.navigate(['/logingin']);
      console.log(data);
    });
  }
  onSubmit(){
    this.loading = true;
    const params = new HttpParams({
      fromObject: {
        grant_type: 'password',
        client_id: 'WebApp',
        client_secret: 'LHFundApp'
      }
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept-Language': this.translate.currentLang,
        'otp':  this.formotp.controls.otp.value,
        'refcode': this.refcode
      })
    };
    this.http.post(environment.serverUrl + '/authorize', params, httpOptions)
      .subscribe(
        (data: any) => {
          localStorage.setItem('currentUser', JSON.stringify(data));
          localStorage.setItem('userInfo', data['memberInfo']);
          this.router.navigate([this.returnUrl]);
          console.log(data);
        },
        error => {     
          this.loading = false;       
          console.log(error);
          console.log(error.error.error_description);
          this.message = error.error.error_description;
          this.loading = false;
          if(error.error.error == 'invalid_grant_login_incorrect')
          {
            setTimeout(() => {
              $('#messag1').modal({
                backdrop: 'static',
                keyboard: false,
                show: true
              });
            }, 100);
          }
          else if(error.error.error == 'invalid_grant_login_otpcount')
          {
            this.loading = true;
            setTimeout(() => {
              $('#messag1').modal({
                backdrop: 'static',
                keyboard: false,
                show: true
              });
            }, 100);
          }else{
            setTimeout(() => {
              $('#message2').modal({
                backdrop: 'static',
                keyboard: false,
                show: true
              });
            }, 100);
          }          
        }
      );
  }
  _onSubmit() {       
    console.log(this.formotp)
    if (this.formotp.valid) {
      this.loading = true;
      this.isNotValid = false;

      const params = new HttpParams({
        fromObject: {
          grant_type: 'password',
          client_id: 'WebApp',
          client_secret: 'LHFundApp'
          /*username: user,
          password: password,*/
        }
      });
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          //'Accept-Language': this.translate.currentLang,
          'otp':  this.formotp.controls.otp.value,
          'refcode': this.refcode
        })
      };

      this.authenticationService.authorize(params,httpOptions)
        .subscribe(
          data => {
            localStorage.setItem('userInfo', data['memberInfo']);
            this.router.navigate([this.returnUrl]);
          },
          error => {            
            console.log(error);
            console.log(error.error.error_description);
            this.message = error.error.error_description;
            this.loading = false;

            if(error.error.error == 'invalid_grant_login_lock'){
              setTimeout(() => {
                $('#message_lock').modal({
                  backdrop: 'static',
                  keyboard: false,
                  show: true
                });
              }, 100);
            }else{
              setTimeout(() => {
                $('#message').modal({
                  backdrop: 'static',
                  keyboard: false,
                  show: true
                });
              }, 100);
            }

          });

    } else {
      this.isNotValid = true;
      this.validateAllFormFields(this.formotp);
    }
  }

  isFieldNotValid1(field: string) {
    return !this.formotp.get(field).valid && this.formotp.get(field).touched

  }

  ValidatorDisplayCss1(field: string) {
    return {
      'has-danger': this.isFieldNotValid1(field)
    };
  }
  
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  navigate(target) {
    var target = target;
    console.log(target);
    this.router.navigate([target], { relativeTo: this.route });
  }
}
