import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseApplicationDataService } from '../../service/base-application-data.service';
import { first } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm, AbstractControl } from '@angular/forms';
import { ProfileService } from '../../service/profile.service'
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { LanguageService } from '../../service/language.service';
declare var $: any;

@Component({
  selector: 'app-consentform',
  templateUrl: './consentform.component.html',
  styleUrls: ['./consentform.component.scss']
})
export class ConsentFormComponent implements OnInit {
  userall: any = {};
  userselect: any = {};
  unitholderno: any = "init";
  formchangepass: FormGroup;
  isNotValid = false;
  res: any = {};
  formotp: FormGroup;
  message: any;
  loading = false;
  email = false;
  sms = false;
  dataDic:any = [];
  data: any = {};
  userInfo:any = {}; 
  lang: Observable<string>;
  idCardNo:"";
  unitHolderName:"";
  consentDate:"";
  consentflag0="";
  consentflag1="";
  consentflag2="";
  consentflag3="";
  THdescription0="";
  THdescription1 = "";
  THdescription2 = "";          
  THdescription3 = "";
  ENdescription0="";
  ENdescription1 = "";
  ENdescription2 = "";          
  ENdescription3 = "";

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private basedataservice: BaseApplicationDataService,
    private profileservice: ProfileService,
    private toastr: ToastrService,
    private langservice: LanguageService,
  ) 
  {
    translate.addLangs(["th", "en"]);
  }

  ngOnInit() {
    this.createFormValidate();
    this.getSelectListUnitholder();
    this.getTBDataDic();
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.langservice.listen().subscribe((m: any) => {
      console.log(m);
      this.lang = m;
    });
    $('#mutual-tab-menu').find('li').removeClass('current');
    $('#mutual-tab-menu').find('li#menu4').addClass('current');
  }
  onChange() {

    for (let i = 0; i < this.userall.unitholderlist.length; i++) {
      if (this.userall.unitholderlist[i].UnitholderId == this.unitholderno.UnitholderId) {
        this.userselect = this.userall.unitholderlist[i];
      }
    }
  }
  getSelectListUnitholder() {
    this.basedataservice.getSelectListUnitholder()
      .pipe(first())
      .subscribe(
        data => {
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
        }, 100);
          this.userall = data;
          this.unitholderno = this.userall.unitholderlist[0];
          this.userselect = this.userall.unitholderlist[0];
        },
        error => {
          console.log(error)

        });
  }
  getTBDataDic() { 
      this.profileservice.gettbdatadic(null)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data)
          this.data = data;
          this.dataDic = this.data.dataDic;          
          this.consentflag0 = this.dataDic[0].ConsentFlag;
          this.consentflag1 = this.dataDic[1].ConsentFlag;
          this.consentflag2 = this.dataDic[2].ConsentFlag;
          //this.consentflag3 = this.dataDic[3].ConsentFlag;
          this.THdescription0 = this.dataDic[0].Description;
          this.THdescription1 = this.dataDic[1].Description;
          this.THdescription2 = this.dataDic[2].Description;          
          //this.THdescription3 = this.dataDic[3].Description;
          this.ENdescription0 = this.dataDic[0].Remark;
          this.ENdescription1 = this.dataDic[1].Remark;
          this.ENdescription2 = this.dataDic[2].Remark;          
          //this.ENdescription3 = this.dataDic[3].Remark;
          //if(this.consentflag3 == "Y"){            
          //this.email = this.dataDic[3].Email == 'Y' ? true:false;            
          //this.sms = this.dataDic[3].SMS == 'Y' ? true:false;      
          //}

          this.idCardNo = this.data.idCardNo;
          this.unitHolderName = this.data.unitHolderName;
          this.consentDate = this.data.consentDate;
          this.loading = false;         
        },
        error => {
          console.log(error)
          this.loading = false;
          this.message = error.error.messages;
        });
}
  createFormValidate() {
    this.formchangepass = this.fb.group({
      currentpassword: [null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[A-Z])(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/)
        ]
      ],
      newpassword: [null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[A-Z])(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/)
        ]
      ]
    })

    this.formotp = this.fb.group({
      otp: [null,
        [
          Validators.required,
          Validators.pattern(/^[0-9]*$/)
        ]
      ]
    })
  }
  resetotp(){
    this.formotp.reset();
  }
  chkconsentflag3Y(){   
    this.email = true;
    this.sms = true;
}
  chkconsentflag3N(){   
      this.email = false;
      this.sms = false;
  }
  onSubmit() {
      //this.isNotValid = false;
      this.loading = true;         
      this.dataDic[0].ConsentFlag = this.consentflag0;
      this.dataDic[1].ConsentFlag = this.consentflag1;
      this.dataDic[2].ConsentFlag = this.consentflag2;
      //this.dataDic[3].Email =  this.email == true ? 'Y' : 'N';
      //this.dataDic[3].SMS = this.sms == true ? 'Y' : 'N';
      //if(this.email == true ||  this.sms == true)
      //  this.dataDic[3].ConsentFlag = 'Y';
      //else        
      //  this.dataDic[3].ConsentFlag = 'N';//this.consentflag3;
        
      this.profileservice.submitconsentform(this.dataDic)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data)            
            this.res = data;
            this.loading = false;
            $('#otpchangeconsentform').modal({
              backdrop: 'static',
              keyboard: false,
              show: true
            });
          },
          error => {
            console.log(error)
            this.loading = false;
            this.message = error.error.messages;
            $('#message').modal({
              backdrop: 'static',
              keyboard: false,
              show: true
            });
          });
  }

  saveconsentform() {
    console.log(this.formotp.controls.otp.value)
    console.log(this.formchangepass)
    if (this.formotp.valid) {
      this.isNotValid = false;
      this.loading = true;
      let Params = new HttpParams();
      Params = Params.append('otp', this.formotp.controls.otp.value);
      Params = Params.append('refcode', this.res.refcode);

      this.profileservice.saveconsentform(this.dataDic, Params)
        .pipe(first())
        .subscribe((data: any) => {          
          this.consentDate = data.consentDate.toString('dd/MM/yyyy');
            this.loading = false;
            $('#otpchangeconsentform').modal('toggle');
            this.translate.get('CONTENT.save-consentform-success').subscribe((res: string) => {
              this.message = res;
          });
           // this.message = "ท่านได้ทำการเปลี่ยนความยินยอมเปิดเผยข้อมูลส่วนบุคคลเรียบร้อยแล้ว";
            $('#message').modal({
              backdrop: 'static',
              keyboard: false,
              show: true
            });
          },
          error => {
            console.log(error)
            this.loading = false;
            this.message = error.error.messages;
          });

    } else {
      this.isNotValid = true;
      this.validateAllFormFields(this.formotp);
    }

  }
  reset(){
    this.message = "";
    this.createFormValidate();
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    })
  }

  isFieldNotValid(field: string) {
    return !this.formchangepass.get(field).valid && this.formchangepass.get(field).touched

  }

  ValidatorDisplayCss(field: string) {
    return {
      'has-danger': this.isFieldNotValid(field)
    };
  }

  isFieldNotValid4(field: string) {
    return !this.formotp.get(field).valid && this.formotp.get(field).touched

  }

  ValidatorDisplayCss4(field: string) {
    return {
      'has-danger': this.isFieldNotValid4(field)
    };
  }

  requestotp() {
    this.loading = true;
    this.basedataservice.requestotp()
      .subscribe((data) => {
        console.log(data);
        this.res = data;
        this.loading = false;
      },
        (error) => {
          console.log(error);
          this.loading = false;
          this.message = error.error.messages;
            setTimeout(() => {
              $('#message').modal({
                backdrop: 'static',
                keyboard: false,
                show: true
              });
            }, 100);
        });
  }
  print() {
    window.focus();
    window.print();
}

}
