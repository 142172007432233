import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit ,} from '@angular/core';
import { Directive, ElementRef, AfterViewChecked, Input, HostListener } from '@angular/core';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { Observable } from 'rxjs';
import { LanguageService } from '../service/language.service';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss',],
})

export class LoginComponent implements OnInit {

  @ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef<any>;
  display: boolean = false;
  @ViewChild('content') private content;
  @ViewChild('disclaimer') private content2;  
  lang: any;
  constructor(
    private scrollbarService: MalihuScrollbarService, 
    private langservice: LanguageService,    
    private translate: TranslateService
  ) {  translate.addLangs(["th", "en"]); }

  ngOnInit() {
   
      this.lang =  this.translate.currentLang;
  
    $('#disclaimer').modal({
      backdrop: 'static',
      keyboard: false,
      show: true
  });
  //this.scrollbarService.initScrollbar('#content-scroll-1', { axis: 'y', theme: 'dark', scrollButtons: { enable: false } });
  } 

}
