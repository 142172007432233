import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../service/authentication.service'
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { HttpParams } from '@angular/common/http';

declare var $: any;



@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  formsighin: FormGroup;
  isNotValid = false;
  langth: boolean;
  langen: boolean;
  foo1: string;
  returnUrl: any;
  User: any = {}
  message: any = "";  
  loading = false;
  twofactorauth: any;
  bypass = "";
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private http: HttpClient) {
    translate.addLangs(["th", "en"]);
  }

  ngOnInit() {
    this.createFormValidate();
    this.foo1 = this.translate.currentLang;
    if (this.foo1 == 'th') {

      this.langth = true;
    }
    else if (this.foo1 == 'en') {
      this.langen = true;
    }

    // reset login status
    if((localStorage.getItem('currentUser') != null && localStorage.getItem('currentUser') != undefined && localStorage.getItem('currentUser') != "")&&(localStorage.getItem('userInfo') != null && localStorage.getItem('userInfo') != undefined && localStorage.getItem('userInfo') != ""))
    {
      this.authenticationService.logout()
      .subscribe((data) => {      
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userInfo');
        window.location.href = "";
      }, function (error) {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userInfo');
        window.location.href = "";
      });
    } 

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/logingin';
    //this.getcogfigLogin();
  }
  getconfigLogin(){
    this.authenticationService.getconfigLogin()
        .subscribe(
          (data: any) => {
            this.twofactorauth = data.twofactorauth;
            this.bypass = data.bypass;
          },
          error => {
            console.log(error.error.messages);
          });
  }
  switchlang(lang) {
    if (lang == 'th') {
      this.translate.use('th');
      this.langen = false;
      this.langth = true;
      localStorage.setItem('lang', lang);

    }
    else if (lang == 'en') {
      this.translate.use('en');
      this.langth = false;
      this.langen = true;
      localStorage.setItem('lang', lang);
    }


  }
  createFormValidate() {
    this.formsighin = this.fb.group({
      username: [null,
        [
          Validators.required
        ]
      ],
      password: [null,
        [
          Validators.required
        ]
      ]
    })
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    })
  }
  onSubmit() {   
      console.log(this.formsighin)
    //if(this.twofactorauth == 1){
      if (this.formsighin.valid) {
        this.loading = true;
        this.isNotValid = false;   
        const params = new HttpParams({
          fromObject: {
            username: this.formsighin.controls.username.value,
            password: this.formsighin.controls.password.value
          }
        });    
        const httpOptions = {
          headers: new HttpHeaders({
            'Accept-Language': this.translate.currentLang,
            'bypass': this.bypass
          })
        };
        this.authenticationService.login(params,httpOptions)
          .subscribe(
            (data: any) => {
              //localStorage.setItem('userInfo', data['memberInfo']);
              this.router.navigate(['/loginotp/form/'+data.refcode+'/'+data.refotp]);
            },
            error => {
              console.log(error.error.messages);
              console.log(error.error.error_description);
              var msgtype ="";
              if(error.error.messages.length > 1){
                msgtype = error.error.messages[0];
                this.message = error.error.messages[1];
  
              }else{
                this.message = error.error.messages;
              }
              this.loading = false;
  
              if(msgtype == 'invalid_grant_login_lock'){
                setTimeout(() => {
                  $('#message_lock').modal({
                    backdrop: 'static',
                    keyboard: false,
                    show: true
                  });
                }, 100);
              }else{
                setTimeout(() => {
                  $('#message').modal({
                    backdrop: 'static',
                    keyboard: false,
                    show: true
                  });
                }, 100);
              }
            });  
      } else {
        this.isNotValid = true;
        this.validateAllFormFields(this.formsighin);
      }
    /*}
    else
    {
      if (this.formsighin.valid) {
        this.loading = true;
        this.isNotValid = false;  
        const params = new HttpParams({
          fromObject: {
            username: this.formsighin.controls.username.value,
            password: this.formsighin.controls.password.value,
            grant_type: 'password',
            client_id: 'WebApp',
            client_secret: 'LHFundApp'
          }
        });    
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept-Language': this.translate.currentLang,
            'bypass': this.bypass
          })
        }; 
        this.authenticationService.authorize(params,httpOptions)
          .pipe(first())
          .subscribe(
            data => {
              localStorage.setItem('userInfo', data['memberInfo']);
              this.router.navigate([this.returnUrl]);
            },
            error => {
              
              console.log(error);
              console.log(error.error.error_description);
              this.message = error.error.error_description;
              this.loading = false;
  
              if(error.error.error == 'invalid_grant_login_lock'){
                setTimeout(() => {
                  $('#message_lock').modal({
                    backdrop: 'static',
                    keyboard: false,
                    show: true
                  });
                }, 100);
              }else{
                setTimeout(() => {
                  $('#message').modal({
                    backdrop: 'static',
                    keyboard: false,
                    show: true
                  });
                }, 100);
              }
  
            });  
      } else {
        this.isNotValid = true;
        this.validateAllFormFields(this.formsighin);
      }
    }*/    
  }
  
  isFieldNotValid1(field: string) {
    return !this.formsighin.get(field).valid && this.formsighin.get(field).touched

  }

  ValidatorDisplayCss1(field: string) {
    return {
      'has-danger': this.isFieldNotValid1(field)
    };
  }
  navigate(target) {
    var target = target;
    console.log(target);
    this.router.navigate([target], { relativeTo: this.route });
  }
}
