import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseApplicationDataService } from '../../service/base-application-data.service';
import { first, map } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EDocumentService } from 'src/app/service/edocument.service';
import { environment } from '../../../environments/environment.prod';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs/internal/Observable';
declare var $: any;

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit, AfterViewInit {
  yearlist: any = [];
  filenamelistrmf: any = [];
  filenamelistltf: any = [];
  filenamelistssf: any = [];
  filenamelistssfx: any = [];
  folderlist: any = [];
  filelist: any = [];
  sub: any={};  
  unitholderlist: any= [];
  unitholder: any = {};
  refcode: any;
  contactzipcode: any;
  year = "";
  idcardno = "";
  foldername ="";
  filename = "";
  expiredate = "";
  active = 0;
  loading = false;
  message: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private basedataservice: BaseApplicationDataService,
    private edocumentservice: EDocumentService,
    private http: HttpClient
  ) {
    translate.addLangs(["th", "en"]);
  }

  ngOnInit() {
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.refcode = params['refcode'] || null;
      this.contactzipcode = params['contactzipcode'] || null;
      console.log(this.refcode);
    });
    if(this.refcode == null && this.contactzipcode != null)
    this.router.navigate(['form']); 
    this.getyearlist();
    this.getconfirmrequest();
    $('#bottom-main-nav li').find('a').removeClass('current');
    $('#bottom-main-nav li#download').find('a').addClass('current');

  }
  ngAfterViewInit() {

      $('#mutual-tab-menu').find('li').removeClass('current');
      $('#mutual-tab-menu').find('li#menu2').addClass('current');
    
  }
  getconfirmrequest() {
    let Params = new HttpParams();
    Params = Params.append('refcode', this.refcode);
    Params = Params.append('contactzipcode', this.contactzipcode);

    this.edocumentservice.getrequestform(Params)
        .pipe(first())
        .subscribe((data: any) => {
              this.unitholder = data.unitholder;
              this.unitholderlist = data.unitholderlist;
              this.expiredate = data.expiredate;
            },
            (error: any) => {
              console.log(error);
              this.loading = false;
              this.message = error.error.messages;
              //this.reset();
              setTimeout(() => {
                $('#message').modal({
                  backdrop: 'static',
                  keyboard: false,
                  show: true
                });
              }, 100);
            });
  }
  getyearlist() {
    this.edocumentservice.getyearlist()
        .pipe(first())
        .subscribe((data: any) => {
              this.yearlist = data.yearlist;
              this.year = this.yearlist[0];
              this.getfolderdownload(this.year);
            },
            error => {
                console.log(error);
            });
  }
  getfolderdownload(year) {    
    $(".se-pre-con").show();
    let Params = new HttpParams();
    Params = Params.append('year', year);
    Params = Params.append('refcode', this.refcode);
    Params = Params.append('contactzipcode', this.contactzipcode);
    this.edocumentservice.getfolderdownload(Params)
        .pipe(first())
        .subscribe((data: any) => {
                this.folderlist = data.folderlist;
                //this.getfiledownload();
                this.getfiledownloadV2(0,this.folderlist[0]);
                console.log(this.filelist.length);
            },
            error => {
              $(".se-pre-con").fadeOut();
                console.log(error);
                if(error.status == 401){
                  if(error.error.Message != "" && error.error.Message != null && error.error.Message != undefined)
                    this.message = error.error.Message;
                  else
                    this.message = "ไม่พบการขอเอกสารช่องทางอิเล็กทรอนิกส์หรือการขอเอกสารอิเล็กทรอนิกส์ครั้งล่าสุดหมดอายุ(" + this.expiredate + ")";
                  $('#message').modal({
                    backdrop: 'static',
                    keyboard: false,
                    show: true
                  });
                }
                else{
                  this.message = "ไฟล์ไม่พร้อมใช้งาน";//error.message;
                  $('#downloadfile').modal({
                    backdrop: 'static',
                    keyboard: false,
                    show: true
                  });
                }
            });
  }
  getfiledownload() {    
    $(".se-pre-con").show();
    let Params = new HttpParams();
    Params = Params.append('year', this.year);
    Params = Params.append('refcode', this.refcode);
    Params = Params.append('contactzipcode', this.contactzipcode);
    this.edocumentservice.getfiledownload(Params)
        .pipe(first())
        .subscribe((data: any) => {
                this.filelist = data.filelist;
                this.active = this.filelist[0].Folder;
                console.log(this.filelist.length);
                $(".se-pre-con").fadeOut();
            },
            error => {
              $(".se-pre-con").fadeOut();
                console.log(error);
            });
  }
  getfiledownloadV2(id,folder) {    
    $(".se-pre-con").show();
    let Params = new HttpParams();
    Params = Params.append('year', this.year);
    Params = Params.append('refcode', this.refcode);
    Params = Params.append('contactzipcode', this.contactzipcode);
    Params = Params.append('foldername', folder);
    this.edocumentservice.getfiledownloadV2(Params)
        .pipe(first())
        .subscribe((data: any) => {
                this.filelist = data.filelist;
                this.active =id;
                console.log(this.filelist.length);
                $(".se-pre-con").fadeOut();
            },
            error => {
              $(".se-pre-con").fadeOut();
                console.log(error);
                if(error.status == 401){
                  this.message = "การขอเอกสารอิเล็กทรอนิกส์ครั้งล่าสุดหมดอายุ(" + this.expiredate + ")";
                  $('#message').modal({
                    backdrop: 'static',
                    keyboard: false,
                    show: true
                  });
                }
                else{
                  this.message = "ไฟล์ไม่พร้อมใช้งาน";//error.message;
                  $('#downloadfile').modal({
                    backdrop: 'static',
                    keyboard: false,
                    show: true
                  });
                }
            });
  }
  downloadfile(foldername,filename,type) {
    this.loading = true;
    this.foldername = foldername;
    this.filename = filename;
    let Params = new HttpParams();
    Params = Params.append('refcode', this.refcode);
    Params = Params.append('contactzipcode', this.contactzipcode);
    var file = {
      CertificateType: type,
      Year: this.year,
      FileName: this.foldername
    }
    
    if (file instanceof HttpParams) {
      return 'application/x-www-form-urlencoded;charset=UTF-8';
    }

    this.edocumentservice.downloadfile(file, Params)    
    .subscribe((data: any) =>{
          console.log(data);          
          saveAs(data, this.filename);
          this.translate.get('Modal.downloadpass').subscribe((res: string) => {
            console.log(res);
            this.message = res;
          });
          setTimeout(() => {
            $('#downloadfile').modal({
              backdrop: 'static',
              keyboard: false,
              show: true
            });
          }, 100);
          this.loading = false;
        },
        async (error) => {
          console.log(error)          
          if(error.status == 401){
            this.message = "การขอเอกสารอิเล็กทรอนิกส์ครั้งล่าสุดหมดอายุ(" + this.expiredate + ")";
            $('#message').modal({
              backdrop: 'static',
              keyboard: false,
              show: true
            });
          }
          else{
            this.message = "ไฟล์ไม่พร้อมใช้งาน";//error.message;
            $('#downloadfile').modal({
              backdrop: 'static',
              keyboard: false,
              show: true
            });
          }
          this.loading = false;
        });

  }

  logout(){
    if(confirm('You want to log out ?')){
    let Params = new HttpParams();
    Params = Params.append('refcode', this.refcode);
    Params = Params.append('contactzipcode', this.contactzipcode);
    this.edocumentservice.logout(Params)    
    .pipe(first())
    .subscribe((data: any) => {
           this.router.navigate(['form']);
        },
        error => {
          $(".se-pre-con").fadeOut();
            console.log(error);
      });
    }
  }
  currentab(id,folder){
    $('.download-tabs').removeClass('current');
    $('#'+id+'-tabs').addClass('current');
    this.getfiledownloadV2(id,folder);

  }
  navigate(target) {
    var target = target;
    this.router.navigate([target], { queryParams: { refcode: this.refcode, contactzipcode:this.contactzipcode }});  
  }
}