import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseApplicationDataService } from '../../service/base-application-data.service';
import { first } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { EDocumentService } from 'src/app/service/edocument.service';
declare var $: any;

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit, AfterViewInit {
  formrequest: FormGroup;
  sub: any = {};  
  unitholderlist: any= [];
  documenttypelist: any = [];
  unitholder: any = {};
  refcode: any;
  contactzipcode: any;
  channeltype: "";
  channeltext: "";
  message: any = "";  
  loading = false;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private basedataservice: BaseApplicationDataService,
    private edocumentservice: EDocumentService,
  ) {
    translate.addLangs(["th", "en"]);
  }

  ngOnInit() {
    this.createFormValidate();
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.refcode = params['refcode'] || null;
      this.contactzipcode = params['contactzipcode'] || null;
      console.log(this.refcode);
    });
    if(this.refcode == null && this.contactzipcode == null)
      this.router.navigate(['form']); 
    this.getconfirmrequest();
    $('#bottom-main-nav li').find('a').removeClass('current');
    $('#bottom-main-nav li#request').find('a').addClass('current');
    //this.getSelectListUnitholder();

  }
  
  ngAfterViewInit() {
      $('#mutual-tab-menu').find('li').removeClass('current');
      $('#mutual-tab-menu').find('li#menu1').addClass('current');
    
  }
  createFormValidate() {
    this.formrequest = this.fb.group({
      UnitholderName: [null,
        [
          Validators.required
        ]
      ],
      ChannelType: [null,
        [
          Validators.required
        ]
      ],
      ChannelText: [null,
        [
          Validators.required
        ]
      ],
      UnitholderNoList:  this.fb.array([]),
      DocumentTypeList: this.fb.array([]),
    });

    console.log(this.formrequest);


  }

  setUnitholderNolist(unitholderno: any[]) {
    const arrFR = this.fb.array(unitholderno);
    this.formrequest.setControl('UnitholderNoList', arrFR);
  }
  setDocumentTypeList(documenttype: any[]) {
    const arrFR = this.fb.array(documenttype);
    this.formrequest.setControl('DocumentTypeList', arrFR);
  }
  selectdocumenttype(index){
    this.formrequest.controls.DocumentTypeList.value[index].Selected = !this.formrequest.controls.DocumentTypeList.value[index].Selected ;
  }
  
  selectunitholderno(index){
    this.formrequest.controls.UnitholderNoList.value[index].Selected = !this.formrequest.controls.UnitholderNoList.value[index].Selected ;
  }
  getconfirmrequest() {    
    this.loading = true;
    let Params = new HttpParams();
    Params = Params.append('refcode', this.refcode);
    Params = Params.append('contactzipcode', this.contactzipcode);

    this.edocumentservice.getrequestform(Params)
        .pipe(first())
        .subscribe((data: any) => {
          this.loading = false;
              this.unitholder = data.unitholder;
              this.unitholderlist = data.unitholderlist;
              this.documenttypelist = data.documenttypelist;
              
              this.setUnitholderNolist(this.unitholderlist);
            this.setDocumentTypeList(this.documenttypelist);
            
            },
            (error: any) => {
              console.log(error);
              this.loading = false;
              this.message = error.error.messages;
              //this.reset();
              setTimeout(() => {
                $('#message').modal({
                  backdrop: 'static',
                  keyboard: false,
                  show: true
                });
              }, 100);
            });
  }
  sendemailrequest() {    
    this.loading = true;
    const request = {
      UnitholderName: this.unitholder.UnitHolderName,
      UnitholderNoList: this.formrequest.controls.UnitholderNoList.value,
      DocumentTypeList: this.formrequest.controls.DocumentTypeList.value,
      ChannelType: this.formrequest.controls.ChannelType.value,
      ChannelText: this.formrequest.controls.ChannelText.value
    }     
    this.edocumentservice.sendemailrequest(request)
        .pipe(first())
        .subscribe(
            data => {               
              this.loading = false;   
              this.message = "ส่งคำขอเอกสารช่องทางอิเล็กทรอนิกส์สำเร็จ"; 
              setTimeout(() => {
                $('#message2').modal({
                  backdrop: 'static',
                  keyboard: false,
                  show: true
                });
              }, 100);
            },
            error => {
              console.log(error);
              this.loading = false;
              this.message = error.error.messages;
              //this.reset();
              setTimeout(() => {
                $('#message2').modal({
                  backdrop: 'static',
                  keyboard: false,
                  show: true
                });
              }, 100);
            });
  }

  changetype(){
    this.formrequest.controls.ChannelText.setValue('');

  }
  navigate(target) {
    var target = target;    
    this.router.navigate([target], { queryParams: { refcode: this.refcode, contactzipcode:this.contactzipcode }});  
  }
}
