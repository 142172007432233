import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute,NavigationExtras } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service'
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BaseApplicationDataService } from '../service/base-application-data.service';
import { ReCaptcha2Component } from '../../../node_modules/ngx-captcha';
import { HttpParams } from '@angular/common/http';
import { LanguageService } from '../service/language.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
})
export class SuccessComponent implements OnInit {

  

  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private basedataservice: BaseApplicationDataService,
    private langservice: LanguageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    ) {
        translate.addLangs(["th", "en"]);
    }

  ngOnInit() {
  }

}
