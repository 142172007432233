import { Component, OnInit, OnDestroy } from '@angular/core';
import { datethai, dateeng } from '../../Share/dateformat'
import { BaseApplicationDataService } from '../../service/base-application-data.service';
import { first } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { ProfileService } from '../../service/profile.service'
import { FormBuilder, FormGroup, FormControl, Validators, NgForm, FormArray } from '@angular/forms';
import { async } from 'q';
import { LanguageService } from '../../service/language.service'
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UnitholderService } from '../../service/unitholder.service';

declare var $: any;
@Component({
    selector: 'app-suitability',
    templateUrl: './suitability.component.html',
    styleUrls: ['./suitability.component.scss']
})
export class SuitabilityComponent implements OnInit {
    userdetail: any;
    page = "over-view";
    currentdate;
    dateformat = datethai;
    dateformatEng = dateeng;
    userall: any = {};
    userselect: any = {};
    unitholderno: any = "init";
    suitest;
    isNotValid: boolean;
    form: FormGroup;
    formans: FormGroup;
    formotp: FormGroup;
    message: any;
    resulttest;
    resultsubmit;
    suitestselected;
    res: Object;
    show: boolean;
    answer: any = [];
    riskprofile;
    lang: Observable<string>;
    unitholdersented: Observable<string>;
    loading = false;
    countquestion = 0;
    subscribedParam;
    constructor(
        private basedataservice: BaseApplicationDataService,
        private profileserice: ProfileService,
        private fb: FormBuilder,
        private langservice: LanguageService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private unitholderservice: UnitholderService
    ) { }

    ngOnInit() {
        this.userdetail = this.basedataservice.getmemberInfo();
        if (this.userdetail.MemberType.toLowerCase() === 'agent' || this.userdetail.MemberType.toLowerCase() === 'company' || this.userdetail.MemberType.toLowerCase() === 'freelance') 
        {            
            this.router.navigate(['/profile/change-password']);

        }
        if (this.userdetail.MemberType.toLowerCase() === 'company') 
        {            
            this.router.navigate(['/profile/edit-profile']);

        }
        else {
            this.route.paramMap.subscribe(params => {
                this.subscribedParam = params.get("unitholder");
                console.log(this.subscribedParam);    
            });
            this.createFormValidate();
            this.getSelectListUnitholder();
    
            $('#mutual-tab-menu').find('li').removeClass('current');
            $('#mutual-tab-menu').find('li#menu2').addClass('current');
    
            this.langservice.listen().subscribe((m: any) => {
                console.log(m);
                this.lang = m;
            })
            this.unitholderservice.listen().subscribe((u: any) => {
                console.log(u);
                this.unitholdersented = u;
            })
        }

    }
    checkpage(page) {
        window.scroll(0, 0);
        console.log(page)
        setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
        }, 100);
        switch (page) {
            case 'suitability-test':
                this.page = "suitability-test";
                break;
            case 'suitability-score':
                this.onsubmit();
                break;
            case 'over-view':
                this.getriskprofile();
                this.page = "over-view";
                break;
            default:
                this.page = "over-view";
                console.log(this.page)
                break;
        }


    }
    resetotp() {
        this.formotp.reset();
    }
    checktype(event) {
        console.log(event.srcElement.attributes.id);
        var idAttr = event.srcElement.attributes.id;
        var value = idAttr.nodeValue;
        console.log(value);
        if (value == 'size10') {
            $('#personal').addClass('active');
            $('#answerC1').find('input[type="radio"]').attr("disabled", "disabled");
            $('#answerC2').find('input[type="radio"]').attr("disabled", "disabled");
            $('#answerC2').find('input[type="text"]').attr("disabled", "disabled");
        }

        else {
            $('#personal').removeClass('active');
            $('#answerC1').find('input[type="radio"]').removeAttr("disabled");
            $('#answerC2').find('input[type="radio"]').removeAttr("disabled");
            $('#answerC2').find('input[type="text"]').removeAttr("disabled");
        }

        if (value == 'size15') {
            $('#company').addClass('active');
            $('#answerP1').find('input[type="radio"]').attr("disabled", "disabled");
            $('#answerP2').find('input[type="radio"]').attr("disabled", "disabled");
        }

        else {
            $('#company').removeClass('active');
            $('#answerP1').find('input[type="radio"]').removeAttr("disabled");
            $('#answerP2').find('input[type="radio"]').removeAttr("disabled");
        }
    }
    disable() {
        console.log("test");
        $('#answerC1').find('input[type="radio"]').attr("disabled", "disabled");
        $('#answerC2').find('input[type="radio"]').attr("disabled", "disabled");
        $('#answerP1').find('input[type="radio"]').attr("disabled", "disabled");
        $('#answerP2').find('input[type="radio"]').attr("disabled", "disabled");
    }

    onChange() {

        for (let i = 0; i < this.userall.unitholderlist.length; i++) {
            if (this.userall.unitholderlist[i].UnitholderId == this.unitholderno.UnitholderId) {
                this.userselect = this.userall.unitholderlist[i];
            }
        }
        this.getsuitest();
        this.getriskprofile();
    }

    getSelectListUnitholder() {
        this.basedataservice.getSelectListUnitholder()
            .pipe(first())
            .subscribe(
                data => {
                    this.userall = data;

                    setTimeout(() => {
                        $('.selectpicker').selectpicker('refresh');

                    }, 100);


                    if (this.subscribedParam) {
                        console.log("here");
                        
                        for (let y = 0; y < this.userall.unitholderlist.length; y++) {
                            if (this.userall.unitholderlist[y].UnitholderNo == this.subscribedParam) {
                                this.unitholderno = this.userall.unitholderlist[y];
                                this.userselect = this.userall.unitholderlist[y];
                               // console.log("here");

                            } else {
                                //console.log("here2");

                            }
                        }
                    } else {
                        console.log("here2");
                        
                        this.unitholderno = this.userall.unitholderlist[0];
                        this.userselect = this.userall.unitholderlist[0];
                    }

                    this.getsuitest();
                    this.getriskprofile();
                },
                error => {
                    console.log(error)

                });
    }

    getsuitest() {

        this.reset();
        let params = new HttpParams().set('unitholderid', this.userselect.UnitholderId);
        this.profileserice.getrisksuitest(params)
            .pipe(first())
            .subscribe(
                data => {
                    this.suitest = data;
                    this.countquestion = this.suitest.question.length;
                    console.log(this.suitest);
                    setTimeout(() => {
                        // this.setdefault();
                        //(this.form.get('questions') as FormArray).setValue(this.suitest);                   
                        this.autocreateformcontrol();
                    }, 100);
                },
                error => {
                    console.log(error)

                });
    }

    autocreateformcontrol() {

        if (this.suitest) {
            for (let y = 0; y < this.suitest.question.length; y++) {
                this.questions.push(
                    this.fb.control('')
                )
                for (let i = 0; i < this.suitest.question[y].Answers.length; i++) {                   
                    this.answers.push(
                        this.fb.control('')
                    )
                    if (this.suitest.question[y].Answers[i].Selected == true) {
                       this.answers.controls[i].setValue(this.suitest.question[y].Answers[i], { onlySelf: true });
                    }else{
                       this.answers.controls[i].setValue(this.suitest.question[y].Answers[i], { onlySelf: false });
                    }                 
                }

                var q = {
                    Id: this.suitest.question[y].Id,
                    Seq: this.suitest.question[y].Seq,
                    Name: this.suitest.question[y].Name,
                    NameEng: this.suitest.question[y].NameEng,
                    UnitHolderID: this.suitest.question[y].unitholderid,    
                    Answers: this.answers        
                }
               
                this.questions.controls[y].setValue(q); 
                this.formans = this.fb.group({
                    answers: this.fb.array([])    
                }); 
            }
        }
        console.log(this.form);
    }

    getriskprofile() {

        let params = new HttpParams().set('unitholderid', this.userselect.UnitholderId);
        this.profileserice.changeUnitholder(params)
            .pipe(first())
            .subscribe(
                data => {
                    console.log(data);
                    this.riskprofile = data['riskProfile'];
                },
                error => {
                    console.log(error);
                    this.message = error.error.messages;
                    $('#message').modal({
                        backdrop: 'static',
                        keyboard: false,
                        show: true
                    });
                });
    }

    get questions() {
        return this.form.get('questions') as FormArray;

    }
    get answers() {
        return this.formans.get('answers') as FormArray;
    }
    changeAnswer(y:any,i:any,e) {
        var ck = $('#'+y+'-'+i).prop('checked');
        if(y ==3){
            this.suitest.question[y].Answers[i].Selected = ck;        
        }
        else
        {
            for(var n =0;n<this.suitest.question[y].Answers.length;n++){
                if(i==n){
                    this.suitest.question[y].Answers[n].Selected = ck;
                }else{
                    this.suitest.question[y].Answers[n].Selected = false;
                }
            }
        }
    }
    createFormValidate() {
        this.form = this.fb.group({
            questions: this.fb.array([])    
        });
        this.formans = this.fb.group({
            answers: this.fb.array([])    
        });
        
        this.formotp = this.fb.group({
            otp: [null,
                [
                    Validators.required,
                    Validators.pattern(/^[0-9]*$/)
                ]
            ]
        })
    }
    onsubmit() {
        console.log(this.form);
        var question = new Array();
        var answers = new Array();
       // if (this.form.valid) {
            this.isNotValid = false;
            this.show = true;
            this.loading = true;
            for (let y = 0; y < this.suitest.question.length; y++) {
                for (let i = 0; i < this.suitest.question[y].Answers.length; i++) {
                    answers.push(
                        {                           
                            Id: this.suitest.question[y].Answers[i].Id,
                            QuestionId:  this.suitest.question[y].Answers[i].QuestionId,
                            Seq: this.suitest.question[y].Answers[i].Seq,
                            Score:  this.suitest.question[y].Answers[i].Score,
                            Name:  this.suitest.question[y].Answers[i].Name,
                            NameEng:  this.suitest.question[y].Answers[i].NameEng,
                            Selected:  this.suitest.question[y].Answers[i].Selected
                                
                        }
                    )
                }
                //var item = this.questions.at(y);
                question.push(
                    {
                        Id: this.suitest.question[y].Id,
                        Seq: this.suitest.question[y].Seq,
                        Name: this.suitest.question[y].Name,
                        NameEng: this.suitest.question[y].NameEng,
                        UnitHolderID: this.suitest.question[y].UnitHolderID,
                        Answers: answers
                    }
                )
                answers = new Array();
            }
            console.log(question);
            this.suitestselected = question;
            console.log(this.suitestselected);
            this.profileserice.calculateriskprofile(this.suitestselected)
                .pipe(first())
                .subscribe(
                    data => {
                        console.log(data);
                        this.loading = false;
                        this.resulttest = data['riskprofilerank'];
                        this.page = "suitability-score";
                    },
                    error => {
                        console.log(error);
                        this.loading = false;
                        this.message = error.error.messages;
                        $('#message').modal({
                            backdrop: 'static',
                            keyboard: false,
                            show: true
                        });
                    });
        //}
        //else {
        //    this.isNotValid = true;
        //    this.validateAllFormFields(this.form);
        //}
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        })
    }

    summitriskprofile() {
        this.loading = true;
        this.profileserice.submiteditriskprofile()
            .pipe(first())
            .subscribe(
                data => {
                    console.log(data);
                    this.loading = false;
                    this.resultsubmit = data;
                    $('#otpsuitest').modal({
                        backdrop: 'static',
                        keyboard: false,
                        show: true
                    });
                },
                error => {
                    console.log(error);
                    this.loading = false;
                    this.message = error.error.messages;
                    $('#message').modal({
                        backdrop: 'static',
                        keyboard: false,
                        show: true
                    });
                });
    }

    updatesuitest() {
        console.log(this.formotp)
        if (this.formotp.valid) {
            this.isNotValid = false;
            this.loading = true;
            let Params = new HttpParams();
            Params = Params.append('otp', this.formotp.controls.otp.value);
            Params = Params.append('refcode', this.resultsubmit.refcode);
            console.log(this.suitestselected);

            this.profileserice.saveriskprofile(this.suitestselected, Params)
                .pipe(first())
                .subscribe(
                    data => {
                        console.log(data);
                        this.loading = false;
                        this.show = false;
                        $('#otpsuitest').modal('toggle');
                        $('#messagesuc').modal({
                            backdrop: 'static',
                            keyboard: false,
                            show: true
                        });
                        this.reset();
                        this.getsuitest();
                    },
                    error => {
                        console.log(error);
                        this.loading = false;
                        this.message = error.error.messages;
                    });

        } else {
            this.isNotValid = true;
            this.validateAllFormFields(this.formotp);
        }
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    isFieldNotValid1(field: string) {
        return !this.formotp.get(field).valid && this.formotp.get(field).touched
    }

    ValidatorDisplayCss1(field: string) {
        return {
            'has-danger': this.isFieldNotValid1(field)
        };
    }

    requestotp() {
        this.loading = true;
        this.basedataservice.requestotp()
            .subscribe((data) => {
                console.log(data);
                this.resultsubmit = data;
                this.loading = false;
            },
            (error) => {
                console.log(error);
                this.loading = false;
                this.message = error.error.messages;
                setTimeout(() => {
                    $('#message').modal({
                        backdrop: 'static',
                        keyboard: false,
                        show: true
                    });
                }, 100);
            });
    }

    reset() {
        this.formotp.reset();
        this.form.reset();
        this.answer = [];
        this.suitest = {};
        this.message = "";
    }

    print() {
        window.focus();
        window.print();
    }


}
