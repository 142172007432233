import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute,NavigationExtras } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service'
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { EDocumentService } from 'src/app/service/edocument.service';
import { BaseApplicationDataService } from '../service/base-application-data.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { HttpParams } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-consentrmf',
  templateUrl: './consentrmf.component.html',
  styleUrls: ['./consentrmf.component.scss']
})
export class ConsentrmfComponent implements OnInit {
  consentform: FormGroup;
  formotp: FormGroup;
  isNotValid = false;
  langth: boolean;
  langen: boolean;
  foo1: string;
  returnUrl: any;
  message: any = "";  
  res: any = {};
  loading = false;  
  date: any;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private edocumentservice: EDocumentService,
    private basedataservice: BaseApplicationDataService,
    private toastr: ToastrService,
    private http: HttpClient) {
    translate.addLangs(["th", "en"]);
  }

  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  ngOnInit() {
    this.createFormValidate();
    this.foo1 = this.translate.currentLang;
    if (this.foo1 == 'th') {

      this.langth = true;
    }
    else if (this.foo1 == 'en') {
      this.langen = true;
    }
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/logingin';
    this.date = document.getElementById('dateofbirth');
    this.consentform.get('firstname').setValidators([Validators.required]);
    this.consentform.get('firstname').updateValueAndValidity();
    this.consentform.get('lastname').setValidators([Validators.required]);
    this.consentform.get('lastname').updateValueAndValidity();    
    this.consentform.get('unitholdername').clearValidators();
    this.consentform.get('unitholdername').updateValueAndValidity();
  }
  switchlang(lang) {
    if (lang == 'th') {
      this.translate.use('th');
      this.langen = false;
      this.langth = true;
      localStorage.setItem('lang', lang);

    }
    else if (lang == 'en') {
      this.translate.use('en');
      this.langth = false;
      this.langen = true;
      localStorage.setItem('lang', lang);
    }


  }
  createFormValidate() {
    this.consentform = this.fb.group({
      membertype: [null,
        [
          Validators.required
          //Validators.minLength(1),
          //Validators.maxLength(50),
        ]
      ],
      firstname: [null,
        [
          Validators.required
          //Validators.minLength(1),
          //Validators.maxLength(50),
        ]
      ],
      lastname: [null,
        [
          Validators.required
          //Validators.minLength(1),
          //Validators.maxLength(50),
        ]
      ],
      unitholdername: [null,
        [
          Validators.required
          //Validators.minLength(1),
          //Validators.maxLength(50),
        ]
      ],
      idcardno: [null,
        [
          Validators.required,
          //Validators.minLength(13),
        ]
      ],
      contactzipcode: [null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.pattern(/^[0-9]*$/)
        ]
      ],
      dateofbirth: [null,
        [
          Validators.required
        ]
      ]
      /*,
      recaptcha: [null,
        [
          Validators.required
        ]
      ]*/
    });
    this.formotp = this.fb.group({
      otp: [null,
        [
          Validators.required,
          Validators.pattern(/^[0-9]*$/)
        ]
      ]
    });
    
  this.consentform.controls.membertype.setValue('person');
  }

isValidDate(date)
{
    var matches = /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/.exec(date);
    if (matches == null) return false;
    var d = parseInt(matches[2]);
    var m = parseInt(matches[1]) - 1;
    var y = parseInt(matches[3]);
    var composedDate = new Date(y, m, d);
    return composedDate.getDate() == d && composedDate.getMonth() == m && composedDate.getFullYear() == y;
}

updateInput(event) {
  event.preventDefault();
  var string = this.getString(event);
  var selectionStart = this.date.selectionStart;
  var selectionEnd = this.date.selectionEnd;
  var selectionLength = selectionEnd - selectionStart;
  var sanitizedString = string.replace(/[^0-9]+/g, '');
  // Do nothing if nothing is added after sanitization
  if (sanitizedString.length === 0 && this.date.value === "") {
  	return;
  }
  // Only paste numbers that will fit
  var valLength = this.date.value.replace(/[^0-9]+/g, '').length;
  var availableSpace = 8 - valLength + selectionLength;
  // If `/` is selected it should not count as available space
  if (selectionStart <= 2 && selectionEnd >= 3) {
  	availableSpace -= 1;
  }
  // Remove numbers that don't fit
  if (sanitizedString.length > availableSpace) {
  	sanitizedString = sanitizedString.substring(0, availableSpace);
  }
  var newCursorPosition = selectionEnd + sanitizedString.length - selectionLength;
  // Add one to cursor position if a `/` gets inserted
  if ((selectionStart <= 2 && newCursorPosition >= 2) || (selectionStart <= 5 && newCursorPosition >= 5)) {
    newCursorPosition += 1;
  }
  // Previous input value before current cursor position
  var valueStart = this.date.value.substring(0, this.date.selectionStart);
  // Previous input value after current cursor position
  var valueEnd = this.date.value.substring(this.date.selectionEnd, this.date.value.length);
  var proposedValue = valueStart + sanitizedString + valueEnd;
	// Remove anything that's not a number
  var sanitized = proposedValue.replace(/[^0-9]+/g, '');
  this.format(sanitized);
  this.date.setSelectionRange(newCursorPosition, newCursorPosition);
}

removeText(event) {
  if (event.key === 'Backspace' || event.type === 'cut') {
    event.preventDefault();
    var selectionStart = this.date.selectionStart;
    var selectionEnd = this.date.selectionEnd;
    var selectionLength = selectionEnd - selectionStart;
    // If pressing backspace with no selected text
    if (selectionLength === 0 && event.type !== 'cut') {
    	selectionStart -= 1;
      // Remove number from before `/` if attempting to delete `/`
      if (selectionStart === 2) {
        selectionStart -= 1;
      }
    }
    var valueStart = this.date.value.substring(0, selectionStart);
    var valueEnd = this.date.value.substring(selectionEnd, this.date.value.length);
    // Account for added `/`
    if (selectionStart === 2) {
      selectionStart += 1;
    }
    var proposedValue = valueStart + valueEnd;
    var sanitized = proposedValue.replace(/[^0-9]+/g, '');
    this.format(sanitized);
    this.date.setSelectionRange(selectionStart, selectionStart);
  }
}

getString(event) {
	if (event.type === 'paste') {
    var clipboardData = event.clipboardData;
    return clipboardData.getData('Text');
  } else {
  	return String.fromCharCode(event.which);
  }
}

format(sanitized) {
	var newValue;
  var day = sanitized.substring(0, 2);
  var month = sanitized.substring(2, 4);
  var year = sanitized.substring(4, 8);
  if (sanitized.length < 2) {
  	newValue = day;
  } else if (sanitized.length >= 2 && sanitized.length < 4){
    newValue = day + '/' + month;
  }else{  
  	newValue = day + '/' + month + '/' + year;
  }
  this.date.value = newValue;
  this.consentform.controls.dateofbirth.setValue(newValue);
  
  var dateofbirth = this.consentform.controls.dateofbirth.value;
}
 



  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    })
  }
  onSubmit() {       
    if (this.consentform.valid) {
      this.loading = true;
      this.isNotValid = false;
      var dateofbirth = this.consentform.controls.dateofbirth.value.split('/');
      const user = {
        MemberType: this.consentform.controls.membertype.value,
        FirstName: this.consentform.controls.firstname.value,
        LastName: this.consentform.controls.lastname.value,
        UnitHolderName: this.consentform.controls.unitholdername.value,
        IDCardNo: this.consentform.controls.idcardno.value,
        DateOfBirth: dateofbirth[2]+'-'+dateofbirth[1]+'-'+dateofbirth[0],
        Contactzipcode: this.consentform.controls.contactzipcode.value,
        //Mobile: this.consentform.controls.mobile.value
      }      
      let fullname= this.consentform.controls.firstname.value+ " "+this.consentform.controls.lastname.value;
      let idcardno= this.consentform.controls.idcardno.value;
      //let Params = new HttpParams();
      //Params = Params.append('encodedresponse', this.consentform.controls.recaptcha.value);
      this.basedataservice.submitconsentrmf(user/*, null Params*/)
            .pipe(first())
            .subscribe((data: any) => {
              this.res = data;        
              this.router.navigate(['consentrmfform'], { queryParams: { refcode: data.refcode,contactzipcode:this.consentform.controls.contactzipcode.value,fullname:fullname,idcardno:idcardno }});   
            },
              (error: any) => {
                console.log(error);
                //this.reset();
                this.loading = false;
                this.message = error.error.messages;
                setTimeout(() => {
                  $('#message').modal({
                    backdrop: 'static',
                    keyboard: false,
                    show: true
                  });
                }, 100);
              });
    } else {
      this.isNotValid = true;
      this.validateAllFormFields(this.consentform);
    }
  }
/*
  confirmrequestform() {
    console.log(this.formotp)
    if (this.formotp.valid) {
      this.isNotValid = false;
      this.loading = true;
      let Params = new HttpParams();
      Params = Params.append('otp', this.formotp.controls.otp.value);
      Params = Params.append('refcode', this.res.refcode);
      const user = {
        FirstName: this.consentform.controls.firstname.value,
        LastName: this.consentform.controls.lastname.value,
        IDCardNo: this.consentform.controls.idcardno.value,
        Mobile: this.consentform.controls.mobile.value
      }     
      console.log(user);
      this.edocumentservice.confirmrequestform(user, Params)
        .pipe(first())
        .subscribe(
          data => {
            this.res = data;
            this.loading = false;
            console.log(data);            
            let route = this.router.config.find(r => r.path === '/download');
            route.data = data;
            this.router.navigateByUrl('/download');
            //this.router.navigate(['request'], data);
          },
          error => {
            this.reset();
            console.log(error);
            this.loading = false;
            this.message = error.error.messages;
          });

    } else {
      this.isNotValid = true;
      this.validateAllFormFields(this.formotp);
    }
  }
*/
  isFieldNotValid1(field: string) {
    return !this.consentform.get(field).valid && this.consentform.get(field).touched

  }

  isFieldNotValid2(field: string) {
    return !this.formotp.get(field).valid && this.formotp.get(field).touched

  }
  ValidatorDisplayCss1(field: string) {
    return {
      'has-danger': this.isFieldNotValid1(field)
    };
  }
  ValidatorDisplayCss2(field: string) {
    return {
      'has-danger': this.isFieldNotValid2(field)
    };
  }
  navigate(target) {
    var target = target;
    console.log(target);
    this.router.navigate([target], { relativeTo: this.route });
  }
  
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }  
  requestotp() {
    this.loading = true;
    this.basedataservice.requestotp()
      .subscribe((data) => {
        console.log(data);
        this.res = data;
        this.loading = false;        
      this.router.navigate(['request'],data);
      },
        (error) => {
          console.log(error);
          this.loading = false;
          this.message = error.error.messages;
            setTimeout(() => {
              $('#message').modal({
                backdrop: 'static',
                keyboard: false,
                show: true
              });
            }, 100);
        });
  }
  resetdate() {
    this.consentform.controls['dateofbirth'].reset();
}
  resetotp(){
    this.formotp.reset();
  }
  reset(): void {
    this.consentform.controls['recaptcha'].reset();
    this.captchaElem.resetCaptcha();
  }
}
