import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../service/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                if((localStorage.getItem('currentUser') != null && localStorage.getItem('currentUser') != undefined && localStorage.getItem('currentUser') != "")&&(localStorage.getItem('userInfo') != null && localStorage.getItem('userInfo') != undefined && localStorage.getItem('userInfo') != ""))
                {
                    this.authenticationService.logout()
                    .subscribe((data) => {      
                    localStorage.removeItem('currentUser');
                    localStorage.removeItem('userInfo');
                    //window.location.href = "";
                    }, function (error) {
                        localStorage.removeItem('currentUser');
                        localStorage.removeItem('userInfo');
                        window.location.href = "";
                    })
                } 
                location.reload();
            }
            
            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}