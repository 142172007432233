import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit ,} from '@angular/core';
import { Directive, ElementRef, AfterViewChecked, Input, HostListener } from '@angular/core';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';

declare var $: any;

@Component({
  selector: 'app-login-otp',
  templateUrl: './login-otp.component.html',
  styleUrls: ['./login.component.scss',],
})

export class LoginOTPComponent implements OnInit {

  display: boolean = false;
  @ViewChild('content') private content;
  @ViewChild('disclaimer') private content2;

  constructor(
    private scrollbarService: MalihuScrollbarService 
  ) {}

  ngOnInit() {    
  this.scrollbarService.initScrollbar('#content-scroll-1', { axis: 'y', theme: 'dark', scrollButtons: { enable: true } });
  }

}
