import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'tofix' })
export class toFixed implements PipeTransform {
  transform(value: any): any {
    var tofix = value.toFixed(2);
    return tofix.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

@Pipe({ name: 'tofix4' })
export class toFixed4 implements PipeTransform {
  transform(value: any): any {
    var tofix = value.toFixed(4);
    var parts = tofix.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
    // return tofix.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
@Pipe({ name: 'status' })
export class Orderstatus implements PipeTransform {
  transform(value: any): any {
      var status
      if((value) == ""){
        status = "รอจัดสรร";
      }
      else if((value) == "Initial"){
        status = "รอจัดสรร";
      }
      else if((value) == "Canceled"){
        status = "ยกเลิก";
      }
      else if((value) == "Allot_Failed"){
        status = "จัดสรรไม่สำเร็จ";
      }
      else if((value) == "Canceled"){
        status = "ยกเลิก";
      }
      else if((value) == "Allot_Completed"){
        status = "จัดสรรเรียบร้อย";
      }
    return status;
  }
}
