import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EDocumentService {

  constructor(private http: HttpClient) { }

  
  confirmrequestform(data,params){
    return this.http.post(environment.serverUrl + '/api/edocument/confirmrequestform', data, { params: params });
  }
  submitrequetform(data,params){
    return this.http.post<any>(environment.serverUrl + '/api/edocument/submitrequetform', data, { params: params });
  }  
  getrequestform(params) {
    return this.http.post(environment.serverUrl + '/api/edocument/getrequestform', null, { params: params });
  }
  sendemailrequest(data) {
    return this.http.post(environment.serverUrl + '/api/edocument/sendemailrequest', data);
  }
  getfolderdownload(params) {
    return this.http.post(environment.serverUrl + '/api/edocument/getfolderdownload', null, { params: params });
  }
  getfiledownload(params) {
    return this.http.post(environment.serverUrl + '/api/edocument/getfiledownload', null, { params: params });
  }
  getfiledownloadV2(params) {
    return this.http.post(environment.serverUrl + '/api/edocument/getfiledownloadV2', null, { params: params });
  }
  downloadfile(data,params) {
    return this.http.post(environment.serverUrl + '/api/edocument/downloadfile', data, {
      params: params,
      responseType : 'blob',
      headers:new HttpHeaders().append('Content-Type','application/json')
    });
  }
  getedocument() {
    return this.http.post(environment.serverUrl + '/api/edocument/getedocument', null);
  }
  sendemailedocument(data) {
    return this.http.post(environment.serverUrl + '/api/edocument/sendemailedocument', data);
  }
  getyearlist() {
    return this.http.post(environment.serverUrl + '/api/edocument/getyearlist', null);
  }
  submitconsentrmf(data,params){
    return this.http.post<any>(environment.serverUrl + '/api/application/submitconsentrmf', data, { params: params });
  }  
  submitconsentrmfform(data,params){
    return this.http.post<any>(environment.serverUrl + '/api/application/submitconsentrmfform', data, { params: params });
  }  
  logout(params){
    return this.http.post(environment.serverUrl + '/api/edocument/logout', null, { params: params });
  }
}
