import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseApplicationDataService } from '../../service/base-application-data.service';
import { RmfLtfService } from '../../service/rmf-ltf.service'
import { first } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageService } from '../../service/language.service';
declare var $: any;
@Component({
  selector: 'app-conclusion',
  templateUrl: './conclusion.component.html',
  styleUrls: ['./conclusion.component.scss']
})
export class ConclusionComponent implements OnInit {
  userall: any = {};
  userselect: any = {};
  unitholderno: any = "init";
  years;
  rmfltfannual;
  ltflist: any[];
  rmflist: any[];
  ssflist: any[];
  tesglist: any[];
  balanceltf: number = 0;
  balancermf: number = 0;
  balancessf: number = 0;
  balancessfx: number = 0;
  balancetesg: number = 0;
  Year;
  nolist: boolean = false;
  lang: Observable<string>;
  loading = false;
  ltfname;
  ltfnameeng;
  rmfname;
  rmfnameeng;
  ssfname;
  ssfnameeng;
  ssfxname;
  ssfxnameeng;
  tesgname;
  tesgnameeng;
  userdetail: any;

  constructor(
    private basedataservice: BaseApplicationDataService,
    private rmfltfservice: RmfLtfService,
    private langservice: LanguageService
  ) { }

  ngOnInit() {

    this.getSelectListUnitholder();

    $('#mutual-tab-menu').find('li').removeClass('current');
    $('#mutual-tab-menu').find('li#menu2').addClass('current');

    var d = new Date();
    var array = new Array();
    for (var i = (d.getFullYear()); i >= 2011; i--) {
      array.push(i);
    }
    this.Year = array;
    console.log(this.Year);
    this.years = d.getFullYear();

    this.langservice.listen().subscribe((m: any) => {
      console.log(m);
      this.lang = m;
    })
    this.userdetail = this.basedataservice.getmemberInfo();
  }

  onChange() {
    for (let i = 0; i < this.userall.unitholderlist.length; i++) {
      if (this.userall.unitholderlist[i].UnitholderId == this.unitholderno.UnitholderId) {
        this.userselect = this.userall.unitholderlist[i];
      }
    }
  }

  getSelectListUnitholder() {
    this.basedataservice.getSelectListUnitholder()
      .pipe(first())
      .subscribe(
        data => {
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
          }, 100);
          this.userall = data;
          this.unitholderno = this.userall.unitholderlist[0];
          this.userselect = this.userall.unitholderlist[0];
        },
        error => {
          console.log(error)
        });
  }

  Onclick() {
    this.loading = true;
    this.nolist = false;
    this.ltfname = "";
    this.ltfnameeng ="";
    this.rmfname ="";
    this.rmfnameeng ="";
    this.ssfname ="";
    this.ssfnameeng ="";
    this.ssfxname ="";
    this.ssfxnameeng ="";
    this.balanceltf = 0;
    this.balancermf =0;
    this.balancessf =0;
    this.balancessfx =0;
    this.balancetesg=0;
    const user = {
      UnitholderID: this.userselect.UnitholderId,
      Year: this.years
    }
    this.rmfltfservice.getorderrmfltfannual(user)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.loading = false;
          this.rmfltfannual = data;
          if (this.rmfltfannual.orderltflist.OrderList == null && this.rmfltfannual.orderrmflist.OrderList == null && this.rmfltfannual.orderssflist.OrderList == null&& this.rmfltfannual.orderstesglist.OrderList == null) {
            this.nolist = true;
            console.log('notlist');
          }if(this.rmfltfannual.orderltflist.OrderList){
            for (let i = 0; i < this.rmfltfannual.orderltflist.OrderList.length; i++) {
                  this.balanceltf += this.rmfltfannual.orderltflist.OrderList[i].CostTotal;
            }
            this.ltfname = this.rmfltfannual.orderltflist.FundTypeName;
            this.ltfnameeng = this.rmfltfannual.orderltflist.FundTypeNameEng;
            
          }
          if(this.rmfltfannual.orderrmflist.OrderList){
            for (let i = 0; i < this.rmfltfannual.orderrmflist.OrderList.length; i++) {
                  this.balancermf += this.rmfltfannual.orderrmflist.OrderList[i].CostTotal;
            }
            this.rmfname = this.rmfltfannual.orderrmflist.FundTypeName;
            this.rmfnameeng = this.rmfltfannual.orderrmflist.FundTypeNameEng;
            
          }
          if(this.rmfltfannual.orderssflist.OrderList){
            for (let i = 0; i < this.rmfltfannual.orderssflist.OrderList.length; i++) {
              this.balancessf += this.rmfltfannual.orderssflist.OrderList[i].CostTotal;
            }
            this.ssfname = this.rmfltfannual.orderssflist.FundTypeName;
            this.ssfnameeng = this.rmfltfannual.orderssflist.FundTypeNameEng;
            
          }
          if(this.rmfltfannual.orderssfxlist.OrderList){
            for (let i = 0; i < this.rmfltfannual.orderssfxlist.OrderList.length; i++) {
              this.balancessfx += this.rmfltfannual.orderssfxlist.OrderList[i].CostTotal;
            }
            this.ssfxname = this.rmfltfannual.orderssfxlist.FundTypeName;
            this.ssfxnameeng = this.rmfltfannual.orderssfxlist.FundTypeNameEng;
            
          }
          if(this.rmfltfannual.orderstesglist.OrderList){
            for (let i = 0; i < this.rmfltfannual.orderstesglist.OrderList.length; i++) {
              this.balancetesg += this.rmfltfannual.orderstesglist.OrderList[i].CostTotal;
            }
            this.tesgname = this.rmfltfannual.orderstesglist.FundTypeName;
            this.tesgnameeng = this.rmfltfannual.orderstesglist.FundTypeNameEng;
            
          }
          //this.format();
         
        },
        error => {
          console.log(error)
          this.loading = false;
        });
  }
  public calTotal(order: any): number {
    let tempTotal: number = 0;
    order.forEach((e: any) => {
        tempTotal += Number(e.Cost);
    });
    return tempTotal;
}
  format() {
    var rmflist = new Array();
    var ltflist = new Array();
    var ssflist = new Array();
    var tesglist =new Array();
    console.log('test');
    var iRows = 1;
    var SumRows = 0;
    var balanceltf = 0;
    var balancermf = 0;
    var balancessf = 0;
    var balancessfx =0;
    var balancetesg=0;
    var balancetesg=0;
    var count = 0;
    var SumRows1 = this.rmfltfannual.orderltflist.length;
    for (let i = 0; i < this.rmfltfannual.orderltflist.length; i++) {

      console.log('fori');
      var nav = 0;
      var unit = 0;
      var amount = 0;
      var count2 = 0;

      SumRows = this.rmfltfannual.orderltflist[i].Order.length;

      for (let y = 0; y < this.rmfltfannual.orderltflist[i].Order.length; y++) {
        console.log('fory');
        var obj;

        balanceltf += parseFloat(this.rmfltfannual.orderltflist[i].Order[y].BalanceAmount);
        nav += parseFloat(this.rmfltfannual.orderltflist[i].Order[y].NAV);
        unit += parseFloat(this.rmfltfannual.orderltflist[i].Order[y].Unit);
        amount += parseFloat(this.rmfltfannual.orderltflist[i].Order[y].BalanceAmount);
        obj = this.rmfltfannual.orderltflist[i].Order[y];

        if (count == 0) {
          // if (this.rmfltfannual.orderltflist[i].FundID == obj.FundID) {

          obj.FundNameGroup = this.rmfltfannual.orderltflist[i].FundName;
          obj.FundNameEngGroup = this.rmfltfannual.orderltflist[i].FundNameEng;
          // }

        }
        if (count2 == 0) {
          obj.FundName2 = obj.FundName;
        }

        if ((y + 1) == SumRows) {
          obj.nav = nav;
          obj.unit = unit;
          obj.amount = amount;

          unit = 0;
          nav = 0;
          amount = 0;
          count2 = 0;
        }
        ltflist.push(obj);
        console.log(obj);
        count++;
        count2++;
        iRows++;
        // if (count == 10) {
        //   count = 0;
        // }
      }
      if((i+1) == SumRows1){
        count = 0;
      }
      
    }
    var count = 0;
    var SumRows2 = this.rmfltfannual.orderrmflist.length;
    for (let i = 0; i < this.rmfltfannual.orderrmflist.length; i++) {
      console.log('fori');

      var nav = 0;
      var unit = 0;
      var amount = 0;
      var count2 = 0;
      SumRows = this.rmfltfannual.orderrmflist[i].Order.length;

      for (let y = 0; y < this.rmfltfannual.orderrmflist[i].Order.length; y++) {
        console.log('fory');
        var obj;

        balancermf += parseFloat(this.rmfltfannual.orderrmflist[i].Order[y].BalanceAmount);
        nav += parseFloat(this.rmfltfannual.orderrmflist[i].Order[y].NAV);
        unit += parseFloat(this.rmfltfannual.orderrmflist[i].Order[y].Unit);
        amount += parseFloat(this.rmfltfannual.orderrmflist[i].Order[y].BalanceAmount);
        obj = this.rmfltfannual.orderrmflist[i].Order[y];

        if (count == 0) {
          // if (this.rmfltfannual.orderrmflist[i].FundID == obj.FundID) {
            obj.FundName2 = obj.FundName;
            obj.FundNameGroup = this.rmfltfannual.orderrmflist[i].FundName;
            obj.FundNameEngGroup = this.rmfltfannual.orderrmflist[i].FundNameEng;
          // }

        }
        if (count2 == 0 ) {
          // if (this.rmfltfannual.orderrmflist[i].FundID == obj.FundID) {
            obj.FundName2 = obj.FundName;
          // }

        }
        if ((y + 1) == SumRows ) {
          obj.nav = nav;
          obj.unit = unit;
          obj.amount = amount;

          unit = 0;
          nav = 0;
          amount = 0;
          count2 = 0;
        }
        rmflist.push(obj);
        console.log(obj);
        count++;
        count2++;
        iRows++;
        // if (count == 10) {
        //   count = 0;
        // }
      }
      if((i+1) == SumRows2){
        count = 0;
      }
    }
    var count = 0;
    var SumRows3 = this.rmfltfannual.orderssflist.length;
    for (let i = 0; i < this.rmfltfannual.orderssflist.length; i++) {
      console.log('fori');

      var nav = 0;
      var unit = 0;
      var amount = 0;
      var count2 = 0;
      SumRows = this.rmfltfannual.orderssflist[i].Order.length;

      for (let y = 0; y < this.rmfltfannual.orderssflist[i].Order.length; y++) {
        console.log('fory');
        var obj;

        balancessf += parseFloat(this.rmfltfannual.orderssflist[i].Order[y].BalanceAmount);
        balancessfx += parseFloat(this.rmfltfannual.orderssflist[i].Order[y].BalanceAmount);
        nav += parseFloat(this.rmfltfannual.orderssflist[i].Order[y].NAV);
        unit += parseFloat(this.rmfltfannual.orderssflist[i].Order[y].Unit);
        amount += parseFloat(this.rmfltfannual.orderssflist[i].Order[y].BalanceAmount);
        obj = this.rmfltfannual.orderssflist[i].Order[y];

        if (count == 0) {
          // if (this.rmfltfannual.orderrmflist[i].FundID == obj.FundID) {
            obj.FundName2 = obj.FundName;
            obj.FundNameGroup = this.rmfltfannual.orderssflist[i].FundName;
            obj.FundNameEngGroup = this.rmfltfannual.orderssflist[i].FundNameEng;
          // }

        }
        if (count2 == 0 ) {
          // if (this.rmfltfannual.orderrmflist[i].FundID == obj.FundID) {
            obj.FundName2 = obj.FundName;
          // }

        }
        if ((y + 1) == SumRows ) {
          obj.nav = nav;
          obj.unit = unit;
          obj.amount = amount;

          unit = 0;
          nav = 0;
          amount = 0;
          count2 = 0;
        }
        ssflist.push(obj);
        console.log(obj);
        count++;
        count2++;
        iRows++;
        // if (count == 10) {
        //   count = 0;
        // }
      }
      if((i+1) == SumRows3){
        count = 0;
      }
    }

    var count = 0;
    var SumRows4 = this.rmfltfannual.orderstesglist.length;
    for (let i = 0; i < this.rmfltfannual.orderstesglist.length; i++) {
      console.log('fori');

      var nav = 0;
      var unit = 0;
      var amount = 0;
      var count2 = 0;
      SumRows = this.rmfltfannual.orderstesglist[i].Order.length;

      for (let y = 0; y < this.rmfltfannual.orderstesglist[i].Order.length; y++) {
        console.log('fory');
        var obj;

        balancessf += parseFloat(this.rmfltfannual.orderstesglist[i].Order[y].BalanceAmount);
        balancessfx += parseFloat(this.rmfltfannual.orderstesglist[i].Order[y].BalanceAmount);
        nav += parseFloat(this.rmfltfannual.orderstesglist[i].Order[y].NAV);
        unit += parseFloat(this.rmfltfannual.orderstesglist[i].Order[y].Unit);
        amount += parseFloat(this.rmfltfannual.orderstesglist[i].Order[y].BalanceAmount);
        obj = this.rmfltfannual.orderssflist[i].Order[y];

        if (count == 0) {
          // if (this.rmfltfannual.orderrmflist[i].FundID == obj.FundID) {
            obj.FundName2 = obj.FundName;
            obj.FundNameGroup = this.rmfltfannual.orderssflist[i].FundName;
            obj.FundNameEngGroup = this.rmfltfannual.orderssflist[i].FundNameEng;
          // }

        }
        if (count2 == 0 ) {
          // if (this.rmfltfannual.orderrmflist[i].FundID == obj.FundID) {
            obj.FundName2 = obj.FundName;
          // }

        }
        if ((y + 1) == SumRows ) {
          obj.nav = nav;
          obj.unit = unit;
          obj.amount = amount;

          unit = 0;
          nav = 0;
          amount = 0;
          count2 = 0;
        }
        ssflist.push(obj);
        console.log(obj);
        count++;
        count2++;
        iRows++;
        // if (count == 10) {
        //   count = 0;
        // }
      }
      if((i+1) == SumRows3){
        count = 0;
      }
    }
    
    this.balanceltf = balanceltf;
    this.balancermf = balancermf;
    this.balancessf = balancessf;
    this.balancessf = balancessfx;
    this.balancetesg = balancetesg;
    this.ltflist = ltflist;
    this.rmflist = rmflist;
    this.ssflist = ssflist;
    this.tesglist = tesglist;
    console.log(this.ltflist);
    console.log(this.balanceltf);
    console.log(this.ssflist);
  }

  print() {
    window.focus();
    window.print();
  }

}
