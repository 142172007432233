import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { datethai, dateeng } from '../Share/dateformat'
import { TranslateService } from '@ngx-translate/core';
import { BaseApplicationDataService } from '../service/base-application-data.service'
import { first, count } from 'rxjs/operators';
import { async } from 'rxjs/internal/scheduler/async';
import { HttpParams } from '@angular/common/http';
import { MyportService } from '../service/myport.service'
import { Chart } from 'angular-highcharts';
import { Observable } from 'rxjs';
import { LanguageService } from '../service/language.service';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { ProfileService } from '../service/profile.service'

import { FormBuilder, FormGroup, FormControl, Validators, NgForm, AbstractControl } from '@angular/forms';
declare var $: any;
@Component({
    selector: 'app-myport',
    templateUrl: './myport.component.html',
    styleUrls: ['./myport.component.scss',]
})

export class MyportComponent implements OnInit {
    @ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef<any>;
    page = "dashboard";
    dateformat = datethai;
    dateformatEng = dateeng;
    userall: any = {};
    userselect: any = {};
    unitholderno: any = "init";
    unitholder: any = 0;
    balancedetail: any = {};
    NAV = 0;
    ProfitLoss = 0;
    useralluniholder: any;
    allunit: boolean = false;
    loading = false;
    chart: any;
    chartdetail: any;
    lang: Observable<string>;
    userdetail: any;
    dataDic:any = [];
    dataConsent: any = {};    
    dataTRDConsent: any = {};    
    trdConsentFlag="";
    consentflag0="";
    consentflag1="";
    consentflag2="";
    consentflag3="";
    THdescription0="";
    THdescription1 = "";
    THdescription2 = "";          
    THdescription3 = "";
    ENdescription0="";
    ENdescription1 = "";
    ENdescription2 = "";          
    ENdescription3 = "";
    email = false;
    sms = false;
    res: any = {};    
    message: any;
    formotp: FormGroup;

    constructor(
        private translate: TranslateService,
        private basedataservice: BaseApplicationDataService,
        private myportservice: MyportService,
        private langservice: LanguageService,
        private scrollbarService: MalihuScrollbarService,
        private profileservice: ProfileService 
    ) {
        translate.addLangs(["th", "en"]);

    }

    ngOnInit() {
        this.userdetail = this.basedataservice.getmemberInfo();
        console.log(this.userdetail);
        this.getSelectListUnitholder(0);

        $('#bottom-main-nav li').find('a').removeClass('current');
        $('#bottom-main-nav li#myport').find('a').addClass('current');

        this.langservice.listen().subscribe((m: any) => {
            console.log(m);
            this.lang = m;
        })
       
    }
    onChange(unitholderid) {
        this.reset();
        if (unitholderid == 0) {
            this.getSelectListUnitholder(unitholderid);
        } else {       
            setTimeout(() => {
                $('.selectpicker').selectpicker('refresh');
            }, 100);
            this.allunit = false;
            for (let i = 0; i < this.userall.unitholderlist.length; i++) {
                if (this.userall.unitholderlist[i].UnitholderId == unitholderid) {
                    this.userselect = this.userall.unitholderlist[i];
                    this.unitholder = unitholderid;
                }
            }            
        this.getbalance();
        }
    }
    print() {
        window.focus();
        window.print();
    }

    strToDateFormat(strDate) {
        return new Date(strDate);
    }

    getSelectListUnitholder(unitholderid) {
        this.unitholder = unitholderid; 
        this.basedataservice.getSelectListUnitholder()
            .pipe(first())
            .subscribe(
                data => {
                    console.log(data);
                    setTimeout(() => {
                        $('.selectpicker').selectpicker('refresh');
                    }, 100);
                    this.userall = data;
                    this.unitholderno = this.userall.unitholderlist[0];
                     this.userselect = this.userall.unitholderlist[0];
                    this.getbalance();
                    if (this.userall.unitholderlist.length > 1) {
                        this.allunit = true;
                    } else {
                        this.userselect = this.userall.unitholderlist[0];
                        this.allunit = false;
                    }
                    
                    this.getCurrentUser();
                },
                error => {
                    console.log(error)

                });
    }

    getbalance() {
        let params = new HttpParams().set('unitholderid', this.unitholder);
        this.myportservice.getbalance(params)
            .subscribe(
                (data) => {
                    console.log(data);
                    this.balancedetail = data;
                    this.calTotal();
                    this.calchart();
                    this.generatechart();
                },
                (error) => {
                    console.log(error);
                }
            )
    }
    calTotal() {
        if (this.balancedetail.holdingbalance) {
            for (let i = 0; i < this.balancedetail.holdingbalance.length; i++) {

                this.NAV += parseFloat(this.balancedetail.holdingbalance[i].BalanceAmountTotal);
                this.ProfitLoss += parseFloat(this.balancedetail.holdingbalance[i].GainLossTotal);
            }
        }


    }
    calchart() {
        var array = new Array();
        var arr = ["#ED2B34", "#00698C", "#189D8C", "#E99C92", "#EFE5E4", "#D5DF30", "#7B99CF"];

        if (this.balancedetail.holdingbalance) {
            for (let i = 0; i < this.balancedetail.holdingbalance.length; i++) {
                var percent = 0;
                percent = (this.balancedetail.holdingbalance[i].BalanceAmountTotal * 100) / this.NAV;
                array.push({ name: this.balancedetail.holdingbalance[i].FundTypeEng, y: percent, color: arr[i] });
            }
        }

        this.chartdetail = array;
        console.log(this.chartdetail);
    }
    getCurrentUser() {
        this.useralluniholder = this.basedataservice.getmemberInfo();
        /*if(this.useralluniholder.TRDConsentFlag == null){                
            $('#trdconsentform').modal({
                backdrop: 'static',
                keyboard: false,
                show: true
            });
            this.getTRDTBDataDic();
        }
        else */
        if(this.useralluniholder.ConsentFlag == null){
            if(this.useralluniholder.MemberType == 'Person' || this.useralluniholder.MemberType == null)
                {                
                    $('#consentform').modal({
                        backdrop: 'static',
                        keyboard: false,
                        show: true
                    });
                    this.getTBDataDic();            
                }
        }
    }
    getTRDTBDataDic() { 
        this.myportservice.gettrdtbdatadic(null)
        .pipe(first())
        .subscribe(
          (data: any) => {
            console.log(data)
            this.trdConsentFlag = data.ConsentFlag; 
            this.loading = false;         
          },
          error => {
            console.log(error)
            this.loading = false;
            //this.message = error.error.messages;
          });
  }
    getTBDataDic() { 

        this.myportservice.gettbdatadic(null)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data)
            this.dataConsent = data;
            this.dataDic = this.dataConsent.dataDic;          
            this.consentflag0 = this.dataDic[0].ConsentFlag;
            this.consentflag1 = this.dataDic[1].ConsentFlag;
            this.consentflag2 = this.dataDic[2].ConsentFlag;
            //this.consentflag3 = null;//this.dataDic[3].ConsentFlag;

            this.THdescription0 = this.dataDic[0].Description;
            this.THdescription1 = this.dataDic[1].Description;
            this.THdescription2 = this.dataDic[2].Description;          
            //this.THdescription3 = this.dataDic[3].Description;

            this.ENdescription0 = this.dataDic[0].Remark;
            this.ENdescription1 = this.dataDic[1].Remark;
            this.ENdescription2 = this.dataDic[2].Remark;          
            //this.ENdescription3 = this.dataDic[3].Remark;
            //if(this.consentflag3 == "Y"){            
            //this.email = this.dataDic[3].Email == 'Y' ? true:false;            
            //this.sms = this.dataDic[3].SMS == 'Y' ? true:false;      
             // }            
            this.loading = false;         
          },
          error => {
            console.log(error)
            this.loading = false;
            //this.message = error.error.messages;
          });
  }
  onSubmit() {
    //this.isNotValid = false;
    this.loading = true;         
    this.dataDic[0].ConsentFlag = this.consentflag0;
    this.dataDic[1].ConsentFlag = this.consentflag1;
    this.dataDic[2].ConsentFlag = this.consentflag2;
    //this.dataDic[3].ConsentFlag = this.consentflag3;
    //this.dataDic[3].Email =  this.email == true ? 'Y' : 'N';
    //this.dataDic[3].SMS = this.sms == true ? 'Y' : 'N';
    //if(this.email == true ||  this.sms == true)
    //    this.dataDic[3].ConsentFlag = 'Y';
    //else        
    //    this.dataDic[3].ConsentFlag = 'N';//this.consentflag3;
    this.profileservice.submitconsentform(this.dataDic)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data)            
          this.res = data;
          this.loading = false;
          $('#otpchangeconsentform').modal({
            backdrop: 'static',
            keyboard: false,
            show: true
          });
        },
        error => {
          console.log(error)
          this.loading = false;
          this.message = error.error.messages;
          $('#message').modal({
            backdrop: 'static',
            keyboard: false,
            show: true
          });
        });
    }

    saveconsentform() {        
        this.loading = true;
        this.dataDic[0].ConsentFlag = this.consentflag0;
        this.dataDic[1].ConsentFlag = this.consentflag1;
        this.dataDic[2].ConsentFlag = this.consentflag2;
        //this.dataDic[3].ConsentFlag = this.consentflag3;
        //this.dataDic[3].Email =  this.email == true ? 'Y' : 'N';
        //this.dataDic[3].SMS = this.sms == true ? 'Y' : 'N';
        //if(this.email == true ||  this.sms == true)
        //    this.dataDic[3].ConsentFlag = 'Y';
        //else        
        //    this.dataDic[3].ConsentFlag = 'N';//this.consentflag3;
        this.profileservice.savefirstconsentform(this.dataDic)
        .pipe(first())
        .subscribe((data: any) => {          
            this.loading = false;    
            var userInfo = JSON.parse(localStorage.getItem('userInfo'));
            userInfo.ConsentFlag ='Y';
            localStorage.setItem('userInfo',  JSON.stringify(userInfo));
            //$('#otpchangeconsentform').modal('toggle');
            //this.message = "ท่านได้ทำการเปลี่ยนความยินยอมเปิดเผยข้อมูลส่วนบุคคลเรียบร้อยแล้ว";
            $('#message').modal({
                backdrop: 'static',
                keyboard: false,
                show: true
            });
            },
            error => {
            console.log(error)
            this.loading = false;
            this.message = error.error.messages;
        });
    }
    savetrdconsentform() {        
        this.loading = true;
        this.dataTRDConsent = {
            consentFlag: this.trdConsentFlag
          }
        this.profileservice.savefirsttrdconsentform(this.dataTRDConsent)
        .pipe(first())
        .subscribe((data: any) => {          
            this.loading = false;   
            var userInfo = JSON.parse(localStorage.getItem('userInfo'));
            userInfo.TRDConsentFlag ='Y';
            localStorage.setItem('userInfo',  JSON.stringify(userInfo));
            //$('#otpchangeconsentform').modal('toggle');
            //this.message = "ท่านได้ทำการเปลี่ยนความยินยอมเปิดเผยข้อมูลส่วนบุคคลเรียบร้อยแล้ว";
            //$('#message').modal({
            //    backdrop: 'static',
            //    keyboard: false,
            //    show: true
            //});
            if(this.useralluniholder.ConsentFlag == null){                
                $('#consentform').modal({
                    backdrop: 'static',
                    keyboard: false,
                    show: true
                });
                this.getTBDataDic();  
            }
            },
            error => {
            console.log(error)
            this.loading = false;
            this.message = error.error.messages;
        });
    }
    chkconsentflag3Y(){   
        this.email = true;
        this.sms = true;
    }
      chkconsentflag3N(){   
          this.email = false;
          this.sms = false;
    }
    reset() {
        this.NAV = 0;
        this.ProfitLoss = 0;
        this.useralluniholder = {};
        this.userselect = {};

    }
    generatechart() {
        this.chart = new Chart({
            chart: {
                type: 'pie'

            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        style: {
                            // color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                        }
                    },
                    showInLegend: true
                }
            },
            series: [{
                // colorByPoint: true,
                data: this.chartdetail
            }]
        });

    }


}
