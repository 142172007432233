export const environment = {
  
  production: true,
  serverUrl: window.location.protocol+"//"+window.location.host,
  successUrl:'/#/transaction/success',
  failUrl:'/#/transaction/fail',
  cancelUrl:'/#/transaction/fail',
  //creditcardUrl:'https://paygate.ktc.co.th/ktc/eng/merchandize/payment/payForm.jsp'
  // serverUrl: 'https://onlineuat.lhfund.co.th'
  creditcardUrl:'https://paygate.ktc.co.th/ktc/eng/merchandize/payment/payForm.jsp'
  //creditcardUrl:'https://testpaygate.ktc.co.th/ktc/eng/merchandize/payment/payForm.jsp'
};
