import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseApplicationDataService } from '../../service/base-application-data.service';
import { first } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { EDocumentService } from 'src/app/service/edocument.service';
declare var $: any;


@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit, AfterViewInit {

  formrequest: FormGroup;
  formotp: FormGroup;
  unitholderlist: any= [];
  documenttypelist: any = [];
  unitholder: any = {};
  res: any = {};
  message: any = "";  
  loading = false;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private basedataservice: BaseApplicationDataService,
    private edocumentservice: EDocumentService,
  ) {
    translate.addLangs(["th", "en"]);
  }

  ngOnInit() {
    this.createFormValidate();
    this.getedocument();  
    
    $('#mutual-tab-menu').find('li').removeClass('current');
    $('#mutual-tab-menu').find('li#menu5').addClass('current'); 
  }
  
  ngAfterViewInit() {
  }
  createFormValidate() {
    this.formrequest = this.fb.group({
      UnitholderName: [null,
        [
          Validators.required
        ]
      ],
      ChannelType: [null,
        [
          Validators.required
        ]
      ],
      ChannelText: [null,
        [
          Validators.required
        ]
      ],
      UnitholderNoList:  this.fb.array([]),
      DocumentTypeList: this.fb.array([]),
    });

    console.log(this.formrequest);
    this.formotp = this.fb.group({
      otp: [null,
        [
          Validators.required,
          Validators.pattern(/^[0-9]*$/)
        ]
      ]
    });

  }

  setUnitholderNolist(unitholderno: any[]) {
    const arrFR = this.fb.array(unitholderno);
    this.formrequest.setControl('UnitholderNoList', arrFR);
  }
  setDocumentTypeList(documenttype: any[]) {
    const arrFR = this.fb.array(documenttype);
    this.formrequest.setControl('DocumentTypeList', arrFR);
  }
  selectdocumenttype(index){
    this.formrequest.controls.DocumentTypeList.value[index].Selected = !this.formrequest.controls.DocumentTypeList.value[index].Selected ;
  }
  
  selectunitholderno(index){
    this.formrequest.controls.UnitholderNoList.value[index].Selected = !this.formrequest.controls.UnitholderNoList.value[index].Selected ;
  }
  getedocument() {    
    this.loading = true;   
    this.edocumentservice.getedocument()
        .pipe(first())
        .subscribe((data: any) => {
          this.loading = false;
              this.unitholder = data.unitholder;
              this.unitholderlist = data.unitholderlist;
              this.documenttypelist = data.documenttypelist;
              
              this.setUnitholderNolist(this.unitholderlist);
              this.setDocumentTypeList(this.documenttypelist);
            
            },
            (error: any) => {
              console.log(error);
              this.loading = false;
              this.message = error.error.messages;
              //this.reset();
              setTimeout(() => {
                $('#message').modal({
                  backdrop: 'static',
                  keyboard: false,
                  show: true
                });
              }, 100);
            });
  }



  sendemailedocument() {    
    this.loading = true;
    const form = {
      UnitholderName: this.unitholder.UnitHolderName,
      UnitholderNoList: this.formrequest.controls.UnitholderNoList.value,
      DocumentTypeList: this.formrequest.controls.DocumentTypeList.value,
      ChannelType: this.formrequest.controls.ChannelType.value,
      ChannelText: this.formrequest.controls.ChannelText.value
    }     
    this.edocumentservice.sendemailedocument(form)
        .pipe(first())
        .subscribe(
            data => {               
              this.loading = false;   
              this.message = "ส่งคำขอเอกสารช่องทางอิเล็กทรอนิกส์สำเร็จ"; 
              setTimeout(() => {
                $('#message2').modal({
                  backdrop: 'static',
                  keyboard: false,
                  show: true
                });
              }, 100);
            },
            error => {
              console.log(error);
              this.loading = false;
              this.message = error.error.messages;
              //this.reset();
              setTimeout(() => {
                $('#message2').modal({
                  backdrop: 'static',
                  keyboard: false,
                  show: true
                });
              }, 100);
            });
  }
  changetype(){
    this.formrequest.controls.ChannelText.setValue('');

  }

  isFieldNotValid(field: string) {
    return !this.formrequest.get(field).valid && this.formrequest.get(field).touched

  }

  ValidatorDisplayCss(field: string) {
    return {
      'has-danger': this.isFieldNotValid(field)
    };
  }

  isFieldNotValid1(field: string) {
    return !this.formotp.get(field).valid && this.formotp.get(field).touched

  }

  ValidatorDisplayCss1(field: string) {
    return {
      'has-danger': this.isFieldNotValid1(field)
    };
  }
  
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }  
  requestotp() {
    this.loading = true;
    this.basedataservice.requestotp()
      .subscribe((data) => {
        console.log(data);
        this.res = data;
        this.loading = false;        
      this.router.navigate(['request'],data);
      },
        (error) => {
          console.log(error);
          this.loading = false;
          this.message = error.error.messages;
            setTimeout(() => {
              $('#message').modal({
                backdrop: 'static',
                keyboard: false,
                show: true
              });
            }, 100);
        });
  }
  resetotp(){
    this.formotp.reset();
  }
}
