import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute,NavigationExtras } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service'
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { EDocumentService } from 'src/app/service/edocument.service';
import { BaseApplicationDataService } from '../service/base-application-data.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { HttpParams } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-consentrmfform',
  templateUrl: './consentrmfform.component.html',
  styleUrls: ['./consentrmfform.component.scss']
})
export class ConsentRMFFormComponent implements OnInit {
  userall: any = {};
  userselect: any = {};
  unitholderno: any = "init";
  formchangepass: FormGroup;
  isNotValid = false;
  res: any = {};
  formotp: FormGroup;
  message: any;
  loading = false;
  email = false;
  sms = false;
  dataconsent:any = {};
  data: any = {};
  year: any =[];
  userInfo:any = {}; 
  idCardNo:"";
  idCardNomark:"";
  unitHolderName:"";
  atYear:any;
  consentDate:"";
  consentFlag="";
  consentform: FormGroup;
  
  lang: string;
  foo1: string;
  returnUrl: any;
  date: any;
  sub: any={};  
  consentYear:any=[];
  refcode: any;
  contactmobile: any; 
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private basedataservice: BaseApplicationDataService,
    private toastr: ToastrService,
    private http: HttpClient) {
    translate.addLangs(["th", "en"]);
  }

  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  ngOnInit() {
    this.lang = this.translate.currentLang;
   
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.refcode = params['refcode'] || null;
      this.contactmobile = params['contactmobile'] || null;
      this.unitHolderName = params['fullname'] || null;
      this.idCardNo = params['idcardno'] || null;
      console.log(this.idCardNo);
    });
    if(this.refcode == null && this.contactmobile != null)
    this.router.navigate(['consentrmf']); 

    this.createFormValidate();
    
    
    $('#mutual-tab-menu').find('li').removeClass('current');
    $('#mutual-tab-menu').find('li#menu5').addClass('current');

  
    //this.year=[2565,2566,2567,2577];

    var date = new Date();
    var yy=date.getFullYear()+543;
    //var yy=2570;
    this.year=[];
    for (let index = yy-1; index >= 2565; index--) {
      this.year.push({
         value:index,
         checked:false,
         edit:false
      });
    }
    //this.year[3].checked=true;
    this.year=this.year.splice(0,10);
    this.year.reverse();
    this.getTBDataDic();
    //console.log('date:'+date);
    //console.error(this.year.reverse());
  }
  onResetCheckbox() {
     //alert(this.consentFlag);
     //f(this.consentFlag!=="T")
    
     this.year.forEach((e) => {
        e.checked=false;
     });
  }
  onChange() {

    for (let i = 0; i < this.userall.unitholderlist.length; i++) {
      if (this.userall.unitholderlist[i].UnitholderId == this.unitholderno.UnitholderId) {
        this.userselect = this.userall.unitholderlist[i];
      }
    }
  }
  resetotp(){
    this.formotp.reset();
  }
  /*
  _onSubmit() {
      //this.isNotValid = false;
      this.loading = true;         
      this.dataconsent = {
        idCardNo: this.idCardNo,
        consentFlag: this.consentFlag,
        mobile:this.contactmobile,
        source:"Web"
      }
      let Params = new HttpParams();
      Params = Params.append('code', this.refcode);      
      this.basedataservice.submitconsentrmfform(this.dataconsent, Params)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data)            
            this.res = data;
            this.loading = false;
            $('#otpchangeconsentrmfform').modal({
              backdrop: 'static',
              keyboard: false,
              show: true
            });
          },
          error => {
            console.log(error)
            this.loading = false;
            this.message = error.error.messages;
            $('#message').modal({
              backdrop: 'static',
              keyboard: false,
              show: true
            });
          });
  }
  */
  get  getChecked(){
   
    return this.year
    .filter(item=>item.checked)
    .map(opt => opt.value)
  }
  onCheckboxChange(event,index) {
    //console.info(event.target.value);
    this.consentFlag="Y";
    this.year[index].checked=event.target.checked;
  }

  onSubmit() {
    //console.log(this.formotp.controls.otp.value)
    //console.log(this.formchangepass)
    //if (this.formotp.valid) {
      this.isNotValid = false;
     
      /*
      let Params = new HttpParams();
      Params = Params.append('otp', this.formotp.controls.otp.value);
      Params = Params.append('refcode', this.res.refcode);
      


      if(this.getChecked.length==0 && this.consentFlag=="T") {
           
           this.translate.get('CONTENT.msg_select_year').subscribe((res: string) => {
              this.message = res;
          });
          $('#message').modal({
            backdrop: 'static',
            keyboard: false,
            show: true
          });
          return;
      }
      */
      this.loading = true;
      this.dataconsent = {
        idCardNo: this.idCardNo,
        consentFlag: this.consentFlag,
        consentYear: this.getChecked,
        mobile:this.contactmobile,
        source:"Web"
      }
      //console.error(this.dataconsent );
       
      this.basedataservice.saveconsentrmfform2(this.dataconsent, null)
        .pipe(first())
        .subscribe((data: any) => {   
          this.loading = false;       
          this.consentDate = data.consentDate.toString('dd/MM/yyyy');
          this.getTBDataDic();
          //this.resetotp()
            //$('#otpchangeconsentrmfform').modal('toggle');
            this.translate.get('CONTENT.save-consent-rmf-form-success').subscribe((res: string) => {
              this.message = res;
          });
            $('#message').modal({
              backdrop: 'static',
              keyboard: false,
              show: true
            });
          },
          error => {
            console.log(error)
            this.loading = false;
            this.message = error.error.messages;
          });

    //} else {
      //this.isNotValid = true;
      //this.validateAllFormFields(this.formotp);
    //}

  }
  getTBDataDic() { 
    var data = {
      refcode: this.refcode,
      mobile: this.contactmobile,
      idCardNo:this.idCardNo
    }
      this.basedataservice.getdataconsentrmf(data)
      .pipe(first())
      .subscribe(
        (data: any) => {  
          this.consentFlag =  data.ConsentFlag;
          this.idCardNo = data.idCardNo;
          this.idCardNomark = data.idCardNoMark;
          //this.unitHolderName = data.unitHolderName;
          this.consentDate = data.consentDate;
          this.loading = false;    
          if( data.consentYear!=null) {
            data.consentYear.forEach((e) => {
                var index = this.year.findIndex((o)=>e==o.value);
                //console.info(index);
                if(index>-1) {
                  this.year[index].checked=true;
                  this.year[index].edit=true;
                }
            });
          }
         // console.info(this.year);
        },
        error => {
          console.log(error)
          this.loading = false;
          this.message = error.error.Message;
          $('#messageUnauthorized').modal({
            backdrop: 'static',
            keyboard: false,
            show: true
          });
        });
    }
    
  createFormValidate() {
    this.formchangepass = this.fb.group({
      currentpassword: [null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[A-Z])(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/)
        ]
      ],
      newpassword: [null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[A-Z])(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/)
        ]
      ]
    })

    this.formotp = this.fb.group({
      otp: [null,
        [
          Validators.required,
          Validators.pattern(/^[0-9]*$/)
        ]
      ]
    })
  }
  reset(){
    this.message = "";
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    })
  }

  isFieldNotValid(field: string) {
    return !this.formchangepass.get(field).valid && this.formchangepass.get(field).touched

  }

  ValidatorDisplayCss(field: string) {
    return {
      'has-danger': this.isFieldNotValid(field)
    };
  }

  isFieldNotValid4(field: string) {
    return !this.formotp.get(field).valid && this.formotp.get(field).touched

  }

  ValidatorDisplayCss4(field: string) {
    return {
      'has-danger': this.isFieldNotValid4(field)
    };
  }

  requestotp() {
    let params = new HttpParams().set('refcode', this.res.refcode);
    this.loading = true;
    this.basedataservice.requestotpbyrefcode(params)
      .subscribe((data) => {
        console.log(data);
        this.res = data;
        this.loading = false;
        this.resetotp();
      },
        (error) => {
          console.log(error);
          this.loading = false;
          this.message = error.error.messages;
          this.resetotp();
            /*setTimeout(() => {
              $('#message').modal({
                backdrop: 'static',
                keyboard: false,
                show: true
              });
            }, 100);*/
        });
  }
  print() {
    window.focus();
    window.print();
  }
  successotp() {
    
  }
  logout() {
     this.router.navigate(['consentrmf']);
  }
  _logout(){
    if(confirm('You want to log out ?')){
    let Params = new HttpParams();
    Params = Params.append('refcode', this.refcode);
    Params = Params.append('contactmobile', this.contactmobile);
    this.basedataservice.logoutconsent(Params)    
    .pipe(first())
    .subscribe((data: any) => {
           this.router.navigate(['consentrmf']);
        },
        error => {
          $(".se-pre-con").fadeOut();
            console.log(error);
      });
    }
  }
}
