import { Component, OnInit } from '@angular/core';
import { BaseApplicationDataService } from '../../service/base-application-data.service';
import { first } from 'rxjs/operators';
import { datethai, dateeng } from '../../Share/dateformat';
import { HttpParams } from '@angular/common/http';
import { ReportService } from '../../service/report.service';
import { Observable } from 'rxjs';
import { LanguageService } from '../../service/language.service';
declare var $: any;
@Component({
  selector: 'app-not-available-list',
  templateUrl: './not-available-list.component.html',
  styleUrls: ['./not-available-list.component.scss']
})
export class NotAvailableListComponent implements OnInit {
  userall: any = {};
  userselect: any = {};
  unitholderno: any = "init";
  dateformat = datethai;
  dateformatEng = dateeng;
  unallocatedreportlist;
  nolist: boolean = false;
  lang: Observable<string>;
  userdetail: any;

  allunit: boolean = false;
  unitholder: any = 0;

  constructor(
    private basedataservice: BaseApplicationDataService,
    private reportservice: ReportService,
    private langservice: LanguageService
  ) { }

  ngOnInit() {

    this.getSelectListUnitholder();
    $('#mutual-tab-menu').find('li').removeClass('current');
    $('#mutual-tab-menu').find('li#menu7').addClass('current');
    this.langservice.listen().subscribe((m: any) => {
      console.log(m);
      this.lang = m;
    });

    this.userdetail = this.basedataservice.getmemberInfo();

  }
  onChange(selectVal) {

    for (let i = 0; i < this.userall.unitholderlist.length; i++) {
      if (this.userall.unitholderlist[i].UnitholderId == selectVal) {
        this.userselect = this.userall.unitholderlist[i];
      }
    }
    this.unitholder = selectVal;
    this.getunallocatedreport();
  }

  getSelectListUnitholder() {
    this.basedataservice.getSelectListUnitholder()
      .pipe(first())
      .subscribe(
        data => {
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
        }, 100);

          // this.userall = data;
          // this.unitholderno = this.userall.unitholderlist[0];
          // this.userselect = this.userall.unitholderlist[0];
          // this.getunallocatedreport();

          this.userall = data;
          this.unitholderno = this.userall.unitholderlist[0];
          if (this.userall.unitholderlist.length > 1) {
              this.allunit = true;
          } else {
              this.userselect = this.userall.unitholderlist[0];
          }
          this.getunallocatedreport();

        },
        error => {
          console.log(error);
        });
  }

  getunallocatedreport() {
    this.nolist = false;
    let params = new HttpParams().set('unitholderid', this.unitholder /*this.userselect.UnitholderId*/);
    this.reportservice.unallocatedreport(params)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);          
          this.unallocatedreportlist = data;
          if(this.unallocatedreportlist.unallocatedreport.length == 0){
            this.nolist = true;
            console.log('notlist');
          }
        },
        error => {
          console.log(error)
        });
  }
  StatusName(value){
    var status
      if((value) == ""){
        status = "รอจัดสรร";
      }
      else if((value) == "Initial"){
        status = "รอจัดสรร";
      }
      else if((value) == "Canceled"){
        status = "ยกเลิก";
      }
      else if((value) == "Allot_Failed"){
        status = "จัดสรรไม่สำเร็จ";
      }
      else if((value) == "Canceled"){
        status = "ยกเลิก";
      }
      else if((value) == "Allot_Completed"){
        status = "จัดสรรแล้ว";
      }
    return status;
  }
  print() {
    window.focus();
    window.print();
  }

  strToDateFormat(strDate) {
    return new Date(strDate);
  }

}
