import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import { WINDOW } from '../_helpers/hostname.interceptor'
@Injectable({
  providedIn: 'root'
})
export class BaseApplicationDataService {
  userDetails: Object;
  memberInfo;

  constructor(@Inject(WINDOW) private window: Window, private http: HttpClient, ) { }
  getHostname(): string {
    return this.window.location.hostname;
  }

  getCurrentUser() {
    this.userDetails = JSON.parse(localStorage.getItem('currentUser'));
    return this.userDetails;
  }
  getmemberInfo() {
    this.memberInfo = JSON.parse(localStorage.getItem('userInfo'));
    return this.memberInfo;
  }
  getSelectListUnitholder() {
    return this.http.post(environment.serverUrl + '/api/profile/getunitholderlist', null);
  }
  getUnitholder(params) {
    return this.http.post(environment.serverUrl + '/api/profile/getProfileInfo', null, { params: params });
  }
  requestotp() {
    return this.http.post(environment.serverUrl + '/api/application/requestotp', null);
  }
  requestotpbyrefcode(params) {
    return this.http.post(environment.serverUrl + '/api/application/requestotpbyrefcode', null, { params: params });
  }
  submitconsentrmf(data){
    return this.http.post<any>(environment.serverUrl + '/api/application/submitconsentrmf', data);
  }  
  submitconsentrmfform(data,params){
    return this.http.post<any>(environment.serverUrl + '/api/application/submitconsentrmfform', data, { params: params });
  }  
  saveconsentrmfform(data,params){
    return this.http.post<any>(environment.serverUrl + '/api/application/saveconsentrmfform', data, { params: params });
  }  
  saveconsentrmfform2(data,params){
    return this.http.post<any>(environment.serverUrl + '/api/application/saveconsentrmfform2', data, { params: params });
  }  
  getdataconsentrmf(data){
    return this.http.post<any>(environment.serverUrl + '/api/application/getdataconsentrmf', data);
  }  
  logoutconsent(params){
    return this.http.post(environment.serverUrl + '/api/application/logoutconsent', null, { params: params });
  }
}
